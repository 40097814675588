import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../session/session.service';
import { API } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient, private session: SessionService) { }

    login(credentials): Observable<any> {
        return this.http.post(API + '/login', credentials);
    }

    logout() {
        this.session.deleteUser();
        this.session.deleteToken();
        return;
    }

    getUser(): Observable<any> {
        return this.http.get(API + '/user');
    }

    verify(): Observable<any> {
        return this.http.get(API + '/valid');
    }
}
