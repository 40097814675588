import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private url: string = API + '/report';

    constructor(private http: HttpClient) { }

    getInventory(): Observable<any> {
        return this.http.get(`${this.url}/inventory`);
    }

    getOrders(from: any, to: any): Observable<any> {
        return this.http.get(`${this.url}/order?from=${from}&to=${to}`);
    }

    getRequests(from: any, to: any): Observable<any> {
        return this.http.get(`${this.url}/request?from=${from}&to=${to}`);
    }

    getLowInventory(type: any): Observable<any> {
        return this.http.get(`${this.url}/low-inventory?product_type=${type}`);
    }

    getInventoryChart(): Observable<any> {
        return this.http.get(`${this.url}/inventory/chart`);
    }

    getOrdersChart(from: any, to: any): Observable<any> {
        return this.http.get(`${this.url}/order/chart?from=${from}&to=${to}`);
    }

    getRequestsChart(from: any, to: any): Observable<any> {
        return this.http.get(`${this.url}/request/chart?from=${from}&to=${to}`);
    }

    getInventoryDetail(id: number): Observable<any> {
        return this.http.get(`${this.url}/inventory/${id}`);
    }

    getOrdersDetail(id: number, from: any, to: any): Observable<any> {
        return this.http.get(`${this.url}/order/${id}?from=${from}&to=${to}`);
    }

    getRequestsDetail(id: number, from: any, to: any): Observable<any> {
        return this.http.get(`${this.url}/request/${id}?from=${from}&to=${to}`);
    }
}
