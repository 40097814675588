import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ReportService } from '../../services/report/report.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportRequestsDetailResolve implements Resolve<any> {

    constructor(private rs: ReportService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = +route.params['id'];
        let from = route.queryParams['from'];
        let to = route.queryParams['to'];
        return this.rs.getRequestsDetail(id, from, to).pipe(map(product => product)).pipe(first());
    }

}
