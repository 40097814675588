import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { CompositeService } from '../../services/composite/composite.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CompositeDetailResolve implements Resolve<any> {

    constructor(private cs: CompositeService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = +route.params['id'];
        return this.cs.getComposite(id).pipe(map(composite => composite)).pipe(first());
    }

}
