import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DashboardService } from '../services/dashboard/dashboard.service';
import * as moment from 'moment';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    public title: string = "Escritorio";
    public ordersDetails: Array<any> = [];
    public monthOrders: MatTableDataSource<any> = null;
    public requestsDetails: Array<any> = [];
    public monthRequests: MatTableDataSource<any> = null;
    public selectedMonth: string = '';
    public selectedChartOption: any = 'Ventas';
    public chartOptions: Array<any> = ['Ventas', 'Compras'];
    public months: Array<any> = [];
    public orders: any;
    public requests: any;
    public sold_weight: any;
    public sold_profit: any;
    public product_weight: any;
    public product_currency: any;
    public pending_orders: any;
    public low_inventory: any;
    public pending_prices: any;
    public ordersChartData: Array<any>;
    public requestsChartData: Array<any>;
    public chartLabels: Array<any> = [];
    public chartOpts: any = {tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.currency.transform(tooltipItem.yLabel, 'MXN', 'symbol', '1.0-0');
            return label;
        }}}, responsive: true, scales: {yAxes: [{ticks: {callback: (value, index, values) => {
            return this.currency.transform(value, 'MXN', 'symbol', '1.0-0');
        }, min: 0}}]}
    };
    public chartColors: Array<any> = [{backgroundColor: 'rgba(82, 169, 255, 0.4)', borderColor: 'rgba(82, 169, 255, 1)'}];
    public dashboardGraphLoading: boolean = true;
    public dashboardDetailLoading: boolean = false;
    public pendingPricesLoading: boolean = true;
    public resumeLoading: boolean = true;
    public pendingOrdersLoading: boolean = true;
    public lowInventoryLoading: boolean = true;
    public monthOrdersColumns: string[] = ['product', 'unit_price', 'amount', 'total'];
    public monthRequestsColumns: string[] = ['product', 'unit_price', 'amount', 'total'];
    public showDetails: boolean = false;
    @ViewChild('ordersPaginator', {read: MatPaginator}) ordersPaginator: MatPaginator;
    @ViewChild('requestsPaginator', {read: MatPaginator}) requestsPaginator: MatPaginator;

    constructor(private route: ActivatedRoute, private ds: DashboardService, private currency: CurrencyPipe) {}

    public ngOnInit(): void {
        let graph_months = 6;

        this.ds.getPendingPrices().subscribe(pending_prices => {
            //this.pending_prices = pending_prices
            this.pending_prices = [];
            this.pendingPricesLoading = false;
        });

        this.ds.getResume().subscribe(resume => {
            this.sold_weight = resume.sold_weight;
            this.sold_profit = resume.sold_profit;
            this.product_weight = resume.product_weight;
            this.product_currency = resume.product_currency;
            this.resumeLoading = false;
        });

        this.ds.getPendingOrders().subscribe(pending_orders => {
            this.pending_orders = pending_orders;
            this.pendingOrdersLoading = false;
        });

        this.ds.getLowInventory().subscribe(low_inventory => {
            this.low_inventory = low_inventory;
            this.lowInventoryLoading = false;
        });

        moment.locale('es');

        for (let i = (graph_months - 1); i >= 0; i--) {
            this.months.push(moment().startOf('month').subtract(i, 'months'));
        }

        this.ds.getGraph().subscribe(data => {
            this.orders = data.orders;
            this.requests = data.requests;
            let orders_data = [];
            let requests_data = [];

            this.months.forEach(month => {
                let month_number = month.format('M');
                // Build Orders Data
                if(this.orders) {
                    this.ordersDetails.push(this.getMonthDetail(this.orders, month_number));
                    orders_data.push(this.getMonthItem(this.orders, month_number));
                } else {
                    this.ordersDetails.push([]);
                    orders_data.push(0);
                }
                // Build Requests Data
                if(this.requests) {
                    this.requestsDetails.push(this.getMonthDetail(this.requests, month_number));
                    requests_data.push(this.getMonthItem(this.requests, month_number));
                } else {
                    this.requestsDetails.push([]);
                    requests_data.push(0);
                }
                this.chartLabels.push(month.format('MMMM/YYYY'));
            });

            this.ordersChartData = [{data: orders_data, label: "Ventas (MX$)"}];
            this.requestsChartData = [{data: requests_data, label: "Compras (MX$)"}];

            this.dashboardGraphLoading = false;
        });
    }

    getMonthDetail(moves, month): Array<any> {
        if(moves.hasOwnProperty(month)) {
            return moves[month];
        }

        return [];
    }

    getMonthItem(moves, month): number {
        if(moves.hasOwnProperty(month)) {
            return this.getMonthTotal(moves[month]);
        }

        return 0;
    }

    getMonthTotal(actions): number {
        let total = 0;
        actions.forEach(function(a) {
            total += parseInt(a.total);
        });

        return total;
    }

    public chartClicked(e: any): void {
        let orders: any, requests: any;
        let orders_ids = [];
        let requests_ids = [];
        this.monthRequests = null;
        this.monthOrders = null;
        if(e.active[0] && (this.ordersDetails[e.active[0]._index].length > 0 || this.requestsDetails[e.active[0]._index].length > 0)) {
            this.selectedMonth = this.chartLabels[e.active[0]._index];
            this.showDetails = true;
            this.dashboardDetailLoading = true;
        } else {
            this.showDetails = false;
        }

        if ((e.active[0] && this.selectedChartOption === 'Ventas') && this.ordersDetails[e.active[0]._index].length > 0) {
            orders = this.ordersDetails[e.active[0]._index];
            orders.forEach(order => orders_ids.push(order.id));
            this.ds.getOrders(orders_ids).subscribe(orders => {
                this.monthOrders = new MatTableDataSource(orders);
                this.monthOrders.paginator = this.ordersPaginator;
                this.dashboardDetailLoading = false;
            });
        }

        if ((e.active[0] && this.selectedChartOption === 'Compras') && this.requestsDetails[e.active[0]._index].length > 0) {
            requests = this.requestsDetails[e.active[0]._index];
            requests.forEach(request => requests_ids.push(request.id));
            this.ds.getRequests(requests_ids).subscribe(requests => {
                this.monthRequests = new MatTableDataSource(requests);
                this.monthRequests.paginator = this.requestsPaginator;
                this.dashboardDetailLoading = false;
            });
        }
    }

}
