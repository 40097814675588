import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ProviderService } from '../services/provider/provider.service';

@Component({
    selector: 'provider-create',
    templateUrl: './provider-create.component.html'
})
export class ProviderCreateComponent implements OnInit {
    public title: string = 'Crear Proveedor';
    public providerForm: FormGroup;
    public loading: boolean = false;

    constructor(public location: Location, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private providerService: ProviderService) { }

    ngOnInit() {
        this.providerForm = this.fb.group({
            name: new FormControl(null, Validators.required)
        });
    }

    handler(): void {
        if(this.providerForm.valid) {
            this.loading = true;
            this.providerService.createProvider(this.providerForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Proveedor Guardado!', '', {duration: 2500});
                setTimeout(() => {this.location.back();}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar el proveedor. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
