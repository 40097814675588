import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CompositeService } from '../services/composite/composite.service';

@Component({
    selector: 'composite-create',
    templateUrl: './composite-create.component.html'
})
export class CompositeCreateComponent implements OnInit {
    public title: string = 'Solicitar de Compuesto';
    public composites: Array<any>;
    public filteredComposites: Observable<any>;
    public compositeForm: FormGroup;
    public compositeSelected: boolean = false;
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private compositeService: CompositeService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.composites = data.formulas;

        this.compositeForm = this.fb.group({
            composite: new FormControl(null, Validators.required),
            prepared_at: new FormControl((new Date()).toISOString(), Validators.required),
            unrestricted: new FormControl(false),
            instructions: new FormControl(null),
            amount: new FormControl(0, Validators.min(1))
        });

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });

        this.filteredComposites = this.compositeForm.controls['composite'].valueChanges
            .pipe(startWith(null))
            .pipe(map(composite => composite && typeof composite === 'object' ? composite.product.name : composite))
            .pipe(map(name => name ? this.filterComposite(name) : this.composites.slice()));
    }

    filterComposite(name: string): any {
        return this.composites.filter(composite => composite.product.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    displayFn(option: any): string {
        return option ? option.product.name : option;
    }

    compositeChange() {
        if(this.compositeForm.controls['composite'].value.product) {
            this.compositeSelected = true;
        }
    }

    handler(): void {
        if(this.compositeForm.valid) {
            this.loading = true;
            this.compositeService.createComposite(this.compositeForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Solicitud de Compuesto Guardada!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/composite')}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar la solicitud de compuesto. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
