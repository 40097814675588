import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { RequestService } from '../services/request/request.service';
import * as moment from 'moment';

@Component({
    selector: 'request-edit',
    templateUrl: './request-edit.component.html'
})
export class RequestEditComponent implements OnInit {
    public title: string = 'Editar Compra';
    public providers: Array<any>;
    public filteredProviders: Observable<any>;
    public products: Array<any>;
    public filteredProducts: Observable<any>;
    public request: any;
    public requestForm: FormGroup;
    public requestBatches: Array<FormGroup> = [];
    public requestBatchesFormArray: FormArray = new FormArray(this.requestBatches);
    public requestBatchesProducts: Array<any> = [];
    public globalTotal: number = 0;
    public globalTotalAmount: number = 0;
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private requestService: RequestService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.request = data.request;
        this.providers = data.providers;
        this.products = data.products;

        let arrived_at = moment(this.request.arrived_at, 'YYYY-MM-DD HH:mm:ss').toDate();

        this.requestForm = this.fb.group({
            provider: new FormControl(this.request.provider, Validators.required),
            is_foreign: new FormControl(this.request.is_foreign),
            customs_number: new FormControl(this.request.customs_number),
            request_number: new FormControl(this.request.request_number),
            arrived_at: new FormControl((new Date(arrived_at)).toISOString(), Validators.required),
            amount: new FormControl(this.request.amount),
            total: new FormControl(this.request.total),
            batches: this.requestBatchesFormArray
        });

        this.request.batches.forEach((batch, i) => {
            this.requestBatchesProducts.push([]);
            let index = this.requestBatchesProducts.length - 1;
            this.requestBatchesFormArray.push(new FormGroup({
                id: new FormControl(batch.id),
                code: new FormControl(batch.code, [Validators.required, Validators.minLength(6)]),
                code_origin: new FormControl(batch.code_origin),
                products: new FormArray(this.requestBatchesProducts[index])
            }));
            batch.batch_product_requests.forEach((batch_product_request) => {
                let productsFormArray = this.requestBatches[i].get('products');
                this.insertProduct(batch_product_request, productsFormArray);
            }, this);
        }, this);

        this.filteredProviders = this.requestForm.controls['provider'].valueChanges
            .pipe(startWith(null))
            .pipe(map(provider => provider && typeof provider === 'object' ? provider.name : provider))
            .pipe(map(name => name ? this.filterProvider(name) : this.providers.slice()));

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.provider_id = (params.provider_id) ? params.provider_id : '0';
        });

        this.updateTotal();
    }

    insertProduct(batch_product_request, productsFormArray): void {
        productsFormArray.push(new FormGroup({
            needs_instructions: new FormControl(batch_product_request.batch_product.needs_instructions),
            instructions: new FormControl(batch_product_request.batch_product.instructions),
            product: new FormControl(batch_product_request.batch_product.product, Validators.required),
            unit_price: new FormControl(batch_product_request.unit_price, [Validators.required, Validators.min(1)]),
            amount: new FormControl(batch_product_request.amount, [Validators.required, Validators.min(1)]),
            amount_per_box: new FormControl(batch_product_request.amount_per_box, [Validators.required, Validators.min(1)]),
            boxes_count: new FormControl(batch_product_request.box_count),
            total: new FormControl(batch_product_request.unit_price * batch_product_request.amount)
        }));
    }

    focusProduct(product: FormControl): void {
        this.filteredProducts = product.valueChanges
            .pipe(startWith(null))
            .pipe(map(product => product && typeof product === 'object' ? product.name : product))
            .pipe(map(name => name ? this.filterProduct(name) : this.products.slice()));
    }

    filterProvider(name: string): any {
        return this.providers.filter(provider => provider.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    filterProduct(name: string): any {
        return this.products.filter(product => product.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    displayFn(option: any): string {
        return option ? option.name : option;
    }

    addRequestBatch(): void {
        this.requestBatchesProducts.push([]);
        let index = this.requestBatchesProducts.length - 1;
        this.requestBatchesFormArray.push(new FormGroup({
            code: new FormControl(null, [Validators.required, Validators.minLength(6)]),
            code_origin: new FormControl(null),
            products: new FormArray(this.requestBatchesProducts[index])
        }));
    }

    addRequestBatchProduct(batch): void {
        batch.controls['products'].push(new FormGroup({
            product: new FormControl(null, Validators.required),
            unit_price: new FormControl(0, [Validators.required, Validators.min(1)]),
            amount: new FormControl(0, [Validators.required, Validators.min(1)]),
            amount_per_box: new FormControl(25, [Validators.required, Validators.min(1)]),
            boxes_count: new FormControl(0),
            total: new FormControl(0)
        }));
    }

    removeBatch(i:number): void {
        this.requestBatchesFormArray.removeAt(i);
    }

    removeBatchProduct(batch: any, j: number): void {
        batch.controls['products'].removeAt(j);
    }

    valueChange(i: number, j: number): void {
        this.requestBatchesProducts[i][j].controls['total'].setValue(this.requestBatchesProducts[i][j].controls['unit_price'].value * this.requestBatchesProducts[i][j].controls['amount'].value);
        this.updateTotal();
    }

    updateBoxes(i: number, j: number): void {
        this.requestBatchesProducts[i][j].controls['amount_per_box'].setValue(this.requestBatchesProducts[i][j].controls['amount'].value / this.requestBatchesProducts[i][j].controls['boxes_count'].value);
    }

    updateTotal(): void {
        this.globalTotal = 0;
        this.globalTotalAmount = 0;
        this.requestBatchesProducts.forEach(function(batch_products) {
            batch_products.forEach(function(product) {
                this.globalTotal += parseInt(product.controls['total'].value);
                this.globalTotalAmount += parseInt(product.controls['amount'].value);
            }, this);
        }, this);
        this.requestForm.controls['amount'].setValue(this.globalTotalAmount);
        this.requestForm.controls['total'].setValue(this.globalTotal);
    }

    handler(): void {
        if(this.requestForm.valid) {
            this.loading = true;
            this.requestService.updateRequest(this.request.id, this.requestForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('Pedido Guardado!', '', {duration: 2500});
                setTimeout(() => {this.location.back();}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar la compra. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
