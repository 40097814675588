import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { startWith, map } from 'rxjs/operators';
import { FormulaService } from '../services/formula/formula.service';

@Component({
    selector: 'formula-create',
    templateUrl: './formula-create.component.html'
})
export class FormulaCreateComponent implements OnInit {
    public title: string = 'Nueva Formula';
    public products: Array<FormGroup> = [];
    public addons: Array<FormGroup> = [];
    public p: Array<any>;
    public filteredProducts: Observable<any>;
    public units: Array<any>;
    public types: Array<any>;
    public families: Array<any>;
    public formulaForm: FormGroup;
    public productsFormArray: FormArray = new FormArray(this.products);
    public addonsFormArray: FormArray = new FormArray(this.addons);
    public loading: boolean = false;

    constructor(public location: Location, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private formulaService: FormulaService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.formulaForm = this.fb.group({
            name: new FormControl(''),
            minimum: new FormControl(0),
            unit_price: new FormControl(0),
            measurement_unit: new FormControl(null, Validators.required),
            product_type: new FormControl(null, Validators.required),
            family: new FormControl(null),
            products: this.productsFormArray,
            addons: this.addonsFormArray,
            notes: new FormControl('')
        });

        this.p = data.products;
        this.units = data.units;
        this.types = data.types;
        this.families = data.families;
    }

    compare(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    focusProduct(product: FormControl): void {
        this.filteredProducts = product.valueChanges
            .pipe(startWith(null))
            .pipe(map(product => product && typeof product === 'object' ? product.name : product))
            .pipe(map(name => name ? this.filterProduct(name) : this.products.slice()));
    }

    filterProduct(name: string): any {
        return this.p.filter(product => product.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    displayFn(option: any): string {
        return option ? option.name : option;
    }

    addProduct(): void {
        this.productsFormArray.push(new FormGroup({
            product: new FormControl(null, Validators.required),
            amount: new FormControl(0, [Validators.required, Validators.min(0.0001)])
        }));
    }

    addAddon(): void {
        this.addonsFormArray.push(new FormGroup({
            addon: new FormControl('', Validators.required),
            amount: new FormControl(0, [Validators.required, Validators.min(0.0001)])
        }));
    }

    removeProduct(i: number): void {
        this.productsFormArray.removeAt(i);
    }

    removeAddon(i: number) : void {
        this.addonsFormArray.removeAt(i);
    }

    handler(): void {
        if(this.formulaForm.valid) {
            this.loading = true;
            this.formulaService.createFormula(this.formulaForm.value).subscribe(() => {
                this.loading = false;
                this.snack.open('¡Formula Guardada!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/formula');}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar la formula. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
