import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SERVER } from '../server';
import { SessionService } from '../services/session/session.service';
import { BatchService } from '../services/batch/batch.service';
import { ProductService } from '../services/product/product.service';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'product-batch',
    templateUrl: './product-batch.component.html'
})
export class ProductBatchComponent implements OnInit {
    public batch: any;
    public server: string = SERVER;
    public unitPriceForm: FormGroup;
    public instructionsForm: FormGroup;
    public blockBatchForm: FormGroup
    public unblockBatchForm: FormGroup
    public approveLoading: boolean = false;
    public unifyLoading: boolean = false;
    public batchApproved: boolean = false;
    public priceLoading: boolean = false;
    public instructionsLoading: boolean = false;
    public blockBatchLoading: boolean = false;
    public unblockBatchLoading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private route: ActivatedRoute, private router: Router, public session: SessionService, private batchService: BatchService, private productService: ProductService, private fb: FormBuilder) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.batch = data.batch;
        this.batch.containers.forEach((container) => {
            container.loading = false;
        });

        this.unitPriceForm = this.fb.group({
            unit_price: new FormControl(this.batch.unit_price, [Validators.required, Validators.min(0)])
        });

        this.instructionsForm = this.fb.group({
            needs_instructions: new FormControl(this.batch.needs_instructions),
            instructions: new FormControl(this.batch.instructions)
        });

        this.blockBatchForm = this.fb.group({
            reason: new FormControl(this.batch.reason, [Validators.required])
        });

        this.unblockBatchForm = this.fb.group({
            reason: new FormControl(this.batch.reason)
        });

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
    }

    unitPriceHandler() {
        if(this.unitPriceForm.valid) {
            this.priceLoading = true;
            this.productService.updateUnitPrice(+this.batch.product_id, +this.batch.batch_id, this.unitPriceForm.value).subscribe((res) => {
                this.priceLoading = false;
                this.snack.open('¡Precio unitario actualizado!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/product/' + this.batch.product_id);}, 2600);
            }, (err) => {
                this.priceLoading = false;
                this.snack.open('No se pudo actualizar el precio unitario. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

    instructionsHandler() {
        if(this.instructionsForm.valid) {
            this.instructionsLoading = true;
            this.productService.updateInstructions(+this.batch.product_id, +this.batch.batch_id, this.instructionsForm.value).subscribe((res) => {
                this.instructionsLoading = false;
                this.snack.open('¡Instrucciones actualizadas!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/product/' + this.batch.product_id);}, 2600);
            }, (err) => {
                this.instructionsLoading = false;
                this.snack.open('No se pudo actualizar la instrucción. Inténtalo más tarde o contacta al administrador del sistema.', 'Cerrar');
            });
        }
    }

    blockBatchHandler() {
        if(this.blockBatchForm.valid) {
            this.blockBatchLoading = true;
            this.productService.blockBatch(+this.batch.product_id, +this.batch.batch_id, this.blockBatchForm.value).subscribe((res) => {
                this.blockBatchLoading = false;
                this.batch.state_id = 5;
                this.snack.open('¡Lote bloqueado correctamente!', '', {duration: 2500});
            }, (err) => {
                this.blockBatchLoading = false;
                this.snack.open('No se pudo bloquear el lote. Inténtalo más tarde o contacta al administrador del sistema.', 'Cerrar');
            });
        } else {
            this.blockBatchLoading = false;
            this.snack.open('La razón de bloqueo es necesaria.', 'Cerrar');
        }
    }

    unblockBatchHandler() {
        if(this.unblockBatchForm.valid) {
            this.unblockBatchLoading = true;
            this.productService.unblockBatch(+this.batch.product_id, +this.batch.batch_id, this.unblockBatchForm.value).subscribe((res) => {
                this.unblockBatchLoading = false;
                this.batch.state_id = 1;
                this.snack.open('¡Lote desbloqueado correctamente!', '', {duration: 2500});
            }, (err) => {
                this.unblockBatchLoading = false;
                this.snack.open('No se pudo desbloquear el lote. Inténtalo más tarde o contacta al administrador del sistema.', 'Cerrar');
            });
        } else {
            this.unblockBatchLoading = false;
        }
    }

    approveBatch(id) {
        this.approveLoading = true;
        this.batchService.approveBatch(+id).subscribe((res) => {
            this.batchApproved = true;
            this.snack.open('¡Lote Aprobado!', '', {duration: 2500});
            setTimeout(() => {this.router.navigateByUrl('/app/product/' + this.batch.product_id);}, 2600);
        }, (err) => {
            this.approveLoading = false;
            this.snack.open('No se pudo aprobar el lote. Inténtalo más tarde.', 'Cerrar');
        });
    }

    updateContainerAmount(container) {
        container.loading = true;
        this.batchService.amountContainer(+container.id, {amount: container.amount}).subscribe((res) => {
            container.loading = false;
            this.snack.open('¡Caja Actualizada!', '', {duration: 2500});
            setTimeout(() => {this.router.navigateByUrl('/app/product/' + this.batch.product_id);}, 2600);
        }, (err) => {
            container.loading = false;
            this.snack.open('No se pudo actualizar la caja. Inténtalo más tarde.', 'Cerrar');
        });
    }

    unifyContainers() {
        this.unifyLoading = true;
        this.batchService.unifyContainers(+this.batch.id).subscribe((res) => {
            this.unifyLoading = false;
            this.snack.open('¡Cajas unificadas!', '', {duration: 2500});
            setTimeout(() => {this.router.navigateByUrl('/app/product/' + this.batch.product_id);}, 2600);
        }, (err) => {
            this.unifyLoading = false;
            this.snack.open('No se pudieron unificar las cajas. Inténtalo más tarde.', 'Cerrar');
        });
    }

}
