import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../services/auth/auth.service';
import { SessionService } from '../services/session/session.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public loading: boolean = false;

    constructor(private fb: FormBuilder, private auth: AuthService, private session: SessionService, private snack: MatSnackBar, private router: Router) { }

    ngOnInit() {
        this.loginForm = this.fb.group({
            login: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
    }

    handler(): void {
        if(this.loginForm.valid) {
            this.loading = true;
            this.auth.login(this.loginForm.value).subscribe(
                (data) => this.loginSuccess(data),
                (error) => this.loginError(error)
            );
        }
    }

    loginSuccess(data) {
        this.session.setUser(data.user);
        this.session.setToken(data.token);
        this.router.navigateByUrl(this.session.getRedirect());
    }

    loginError(e) {
        this.loading = false;
        this.snack.open(e.error.message, 'Cerrar');
    }

}
