import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OrderService } from '../services/order/order.service';
//import { OrdersDataSource } from '../datasources/orders-data-source';
import { MatSnackBar } from '@angular/material';
import { SERVER } from '../server';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, catchError, map, startWith, switchMap } from 'rxjs/operators'
import { Observable, merge, fromEvent, of as observableOf } from 'rxjs';

@Component({
    selector: 'order-search',
    templateUrl: './order-search.component.html'
})
export class OrderSearchComponent implements OnInit {
    public title: string = 'Buscar Ventas';
    public ordersColumns = ['id', 'code', 'client', 'created_at', 'delivered_at', 'total', 'state'];
    public orders: any;
    public states: any;
    public clients: any;
    public selectedState: any = '0';
    public selectedClient: any = '0';
    public params: any = {};
    public loading: boolean = false;
    public from: any;
    public to: any;
    public fresh: boolean = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 0;
    public searchForm: FormGroup;
    public products: Array<any>;
    public filteredProducts: Observable<any>;

    constructor(public location: Location, private route: ActivatedRoute, private orderService: OrderService, private snack: MatSnackBar, private router: Router, private fb: FormBuilder) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        //this.orders = new MatTableDataSource([]);
        this.states = data.states;
        this.clients = data.clients;
        this.products = data.products;

        //this.orders.paginator = this.paginator;

        moment.locale('es');
        this.from = moment().startOf('month').subtract(7, 'months').toDate().toISOString();
        this.to = moment().startOf('month').subtract(6, 'months').toDate().toISOString();
        this.searchForm = this.fb.group({
            s: new FormControl(null),
            to: new FormControl(this.to),
            from: new FormControl(this.from),
            page: new FormControl(0),
            state_id: new FormControl('0'),
            client_id: new FormControl('0'),
            product: new FormControl(null)
        });
        this.route.queryParams.subscribe(params => {
            if(Object.entries(params).length > 0 && params.constructor === Object) {
                //this.from = params.from;
                //this.to = params.to;
                this.paginator.pageIndex = (params.page) ? params.page : 0;
                //this.params.s = (params.s) ? params.s : null;
                //this.selectedState = (params.state_id) ? params.state_id : '0';
                //this.selectedClient = (params.client_id) ? params.client_id : '0';
                let product = null;
                if(params.product_id && params.product_id !== '0') {
                    product = this.products.find(product => product.id === parseInt(params.product_id));
                }
                this.searchForm.controls['s'].setValue((params.s) ? params.s : null);
                this.searchForm.controls['to'].setValue(params.to);
                this.searchForm.controls['from'].setValue(params.from);
                this.searchForm.controls['page'].setValue(this.paginator.pageIndex);
                this.searchForm.controls['state_id'].setValue((params.state_id) ? parseInt(params.state_id) : '0');
                this.searchForm.controls['client_id'].setValue((params.client_id) ? parseInt(params.client_id) : '0');
                this.searchForm.controls['product'].setValue((product) ? product : null);
                this.filteredProducts = this.searchForm.controls['product'].valueChanges
                    .pipe(startWith(null))
                    .pipe(map(product => product && typeof product === 'object' ? product.name : product))
                    .pipe(map(name => name ? this.filterProduct(name) : this.products.slice()));
                this.loadOrders();
            }
        });
    }

    filterProduct(name: string): any {
        return this.products.filter(product => product.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    focusProduct(product: FormControl): void {
        this.filteredProducts = product.valueChanges
            .pipe(startWith(null))
            .pipe(map(product => product && typeof product === 'object' ? product.name : product))
            .pipe(map(name => name ? this.filterProduct(name) : this.products.slice()));
    }

    displayFn(option: any): string {
        return option ? option.name : option;
    }

    compare(o1: any, o2: any): boolean {
        return ((typeof o1 === 'object') && (typeof o2 === 'object')) ? o1.id === o2.id : o1 === o2;
    }

    fromDate(event: MatDatepickerInputEvent<Date>) {
        //let from_date = event.value.toISOString();
        let from_date = moment(event.value).toDate().toISOString();
        this.from = from_date;
        this.searchForm.controls['from'].setValue(this.from);
    }

    toDate(event: MatDatepickerInputEvent<Date>) {
        let to_date = moment(event.value).toDate().toISOString();
        this.to = to_date;
        this.searchForm.controls['to'].setValue(this.to);
    }

    searchOrders() {
        console.log(this.searchForm.controls['product'].value.id);
        if(this.fresh) {
            this.loadOrders();
        } else {
            //this.params.page = this.paginator.pageIndex = 0;
            this.paginator.pageIndex = 0;
            this.searchForm.controls['page'].setValue(this.paginator.pageIndex);
            this.reloadOrders();
        }
    }

    getOrders() {
        this.loading = true;
        /*this.params.state_id = this.selectedState;
        this.params.client_id = this.selectedClient;
        this.params.from = this.from;
        this.params.to = this.to;*/
        let params = {
            s: this.searchForm.controls['s'].value,
            to: this.searchForm.controls['to'].value,
            from: this.searchForm.controls['from'].value,
            page: this.searchForm.controls['page'].value,
            state_id: this.searchForm.controls['state_id'].value,
            client_id: this.searchForm.controls['client_id'].value,
            product_id: (this.searchForm.controls['product'].value) ? this.searchForm.controls['product'].value.id : '0'
        };
        this.orderService.searchOrders(params).subscribe((orders) => {
            this.loading = false;
            this.orders = new MatTableDataSource(orders);
            this.orders.paginator = this.paginator;
        }, () => {
            this.loading = false;
        });
    }

    reloadOrders() {
        this.loading = true;
        this.searchForm.controls['page'].setValue(this.paginator.pageIndex);
        /*this.params.page = this.paginator.pageIndex;
        this.params.state_id = this.selectedState;
        this.params.client_id = this.selectedClient;
        this.params.from = this.from;
        this.params.to = this.to;*/
        let params = {
            s: this.searchForm.controls['s'].value,
            to: this.searchForm.controls['to'].value,
            from: this.searchForm.controls['from'].value,
            page: this.searchForm.controls['page'].value,
            state_id: this.searchForm.controls['state_id'].value,
            client_id: this.searchForm.controls['client_id'].value,
            product_id: (this.searchForm.controls['product'].value) ? this.searchForm.controls['product'].value.id : '0'
        };
        this.orderService.searchOrders(params).subscribe(data => {
            this.loading = false;
            this.resultsLength = data.total_count;
            this.orders = data.items;
            let params = {
                s: this.searchForm.controls['s'].value,
                to: this.searchForm.controls['to'].value,
                from: this.searchForm.controls['from'].value,
                page: this.searchForm.controls['page'].value,
                state_id: this.searchForm.controls['state_id'].value,
                client_id: this.searchForm.controls['client_id'].value,
                product_id: (this.searchForm.controls['product'].value) ? this.searchForm.controls['product'].value.id : '0'
            };
            this.router.navigate(['/app/order/search'], {queryParams: params});
        });
    }

    loadOrders() {
        this.fresh = false;
        this.paginator.page.pipe(
            startWith({}),
            switchMap(() => {
                this.loading = true;
                this.searchForm.controls['page'].setValue(this.paginator.pageIndex);
                /*this.params.page = this.paginator.pageIndex;
                this.params.state_id = this.selectedState;
                this.params.client_id = this.selectedClient;
                this.params.from = this.from;
                this.params.to = this.to;*/
                let params = {
                    s: this.searchForm.controls['s'].value,
                    to: this.searchForm.controls['to'].value,
                    from: this.searchForm.controls['from'].value,
                    page: this.searchForm.controls['page'].value,
                    state_id: this.searchForm.controls['state_id'].value,
                    client_id: this.searchForm.controls['client_id'].value,
                    product_id: (this.searchForm.controls['product'].value) ? this.searchForm.controls['product'].value.id : '0'
                };
                return this.orderService!.searchOrders(params);
            }),
            map(data => {
                this.loading = false;
                this.resultsLength = data.total_count;
                this.searchForm.controls['page'].setValue(this.paginator.pageIndex);
                /*this.params.page = this.paginator.pageIndex;
                this.params.state_id = this.selectedState;
                this.params.client_id = this.selectedClient;
                this.params.from = this.from;
                this.params.to = this.to;*/
                let params = {
                    s: this.searchForm.controls['s'].value,
                    to: this.searchForm.controls['to'].value,
                    from: this.searchForm.controls['from'].value,
                    page: this.searchForm.controls['page'].value,
                    state_id: this.searchForm.controls['state_id'].value,
                    client_id: this.searchForm.controls['client_id'].value,
                    product_id: (this.searchForm.controls['product'].value) ? this.searchForm.controls['product'].value.id : '0'
                };
                this.router.navigate(['/app/order/search'], {queryParams: params});
                return data.items;
            }),
            catchError(() => {
                this.loading = false;
                return observableOf([]);
            })
        ).subscribe(data => this.orders = data);
    }

}
