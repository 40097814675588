import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session/session.service';

@Injectable({
    providedIn: 'root'
})
export class OutGuard implements CanActivate {
    constructor(private session: SessionService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(! this.session.isAuthenticated()) {
            return true;
        }

        this.router.navigateByUrl(this.session.getRedirect());
        return false;
    }

}
