import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE, MatToolbarModule, MatSidenavModule, MatMenuModule, MatIconModule, MatButtonModule, MatTableModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatSlideToggleModule, MatPaginatorModule, MatPaginatorIntl, MatCardModule, MatListModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatSnackBarModule, MatProgressSpinnerModule, MatDialogModule } from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { ChartsModule } from 'ng2-charts';
import { MomentModule } from 'angular2-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationComponent } from './application/application.component';
import { ClientComponent } from './client/client.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { CompositeComponent } from './composite/composite.component';
import { CompositeCreateComponent } from './composite-create/composite-create.component';
import { CompositeDetailComponent } from './composite-detail/composite-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormulaComponent } from './formula/formula.component';
import { FormulaCreateComponent } from './formula-create/formula-create.component';
import { FormulaDetailComponent } from './formula-detail/formula-detail.component';
import { FormulaEditComponent } from './formula-edit/formula-edit.component';
import { LoginComponent } from './login/login.component';
import { OrderComponent } from './order/order.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderExecuteComponent } from './order-execute/order-execute.component';
import { OrderGenerateLabelComponent } from './order-generate-label/order-generate-label.component';
import { OrderReturnComponent } from './order-return/order-return.component';
import { OrderSearchComponent } from './order-search/order-search.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ProductComponent } from './product/product.component';
import { ProductBatchComponent } from './product-batch/product-batch.component';
import { ProductCreateComponent } from './product-create/product-create.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProviderCreateComponent } from './provider-create/provider-create.component';
import { ReportComponent } from './report/report.component';
import { ReportInventoryChartComponent } from './report-inventory-chart/report-inventory-chart.component';
import { ReportInventoryDetailComponent } from './report-inventory-detail/report-inventory-detail.component';
import { ReportOrdersChartComponent } from './report-orders-chart/report-orders-chart.component';
import { ReportOrdersDetailComponent } from './report-orders-detail/report-orders-detail.component';
import { ReportRequestsChartComponent } from './report-requests-chart/report-requests-chart.component';
import { ReportRequestsDetailComponent } from './report-requests-detail/report-requests-detail.component';
import { RequestComponent } from './request/request.component';
import { RequestCreateComponent } from './request-create/request-create.component';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { RequestEditComponent } from './request-edit/request-edit.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { TokenInterceptor } from './interceptors/token-interceptor';
import { ApplicationInterceptor } from './interceptors/application-interceptor';
import { DashboardResolve } from './resolves/dashboard/dashboard-resolve.service';
import { DashboardService } from './services/dashboard/dashboard.service';

import { MatPaginatorIntlEs } from './mat-paginator-intl-es';
import { ProviderComponent } from './provider/provider.component';
import { ProviderEditComponent } from './provider-edit/provider-edit.component';

@NgModule({
    declarations: [
        AppComponent,
        ApplicationComponent,
        ClientComponent,
        ClientCreateComponent,
        ClientEditComponent,
        CompositeComponent,
        CompositeCreateComponent,
        CompositeDetailComponent,
        DashboardComponent,
        FormulaComponent,
        FormulaCreateComponent,
        FormulaDetailComponent,
        FormulaEditComponent,
        LoginComponent,
        OrderComponent,
        OrderCreateComponent,
        OrderDetailComponent,
        OrderEditComponent,
        OrderExecuteComponent,
        OrderGenerateLabelComponent,
        OrderReturnComponent,
        OrderSearchComponent,
        FilterPipe,
        ProductComponent,
        ProductBatchComponent,
        ProductCreateComponent,
        ProductDetailComponent,
        ProductEditComponent,
        ProviderCreateComponent,
        ReportComponent,
        ReportInventoryChartComponent,
        ReportInventoryDetailComponent,
        ReportOrdersChartComponent,
        ReportOrdersDetailComponent,
        ReportRequestsChartComponent,
        ReportRequestsDetailComponent,
        RequestComponent,
        RequestCreateComponent,
        RequestDetailComponent,
        RequestEditComponent,
        ErrorDialogComponent,
        ConfirmDialogComponent,
        ProviderComponent,
        ProviderEditComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatToolbarModule,
        MatSidenavModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatCardModule,
        MatListModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        LayoutModule,
        ChartsModule,
        MomentModule,
        AppRoutingModule
    ],
    providers: [
        DashboardService,
        DashboardResolve,
        CurrencyPipe,
        DecimalPipe,
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true},
        {provide: MAT_DATE_LOCALE, useValue: 'es'},
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs}
    ],
    entryComponents: [ErrorDialogComponent, ConfirmDialogComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
