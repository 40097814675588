import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import { FormulaService } from '../services/formula/formula.service';
import { switchMap } from 'rxjs/operators';
import { SERVER } from '../server';
import { SessionService } from '../services/session/session.service';

@Component({
    selector: 'formula-detail',
    templateUrl: './formula-detail.component.html'
})
export class FormulaDetailComponent implements OnInit {
    public formula: any;
    public server: string = SERVER;

    constructor(public location: Location, private session: SessionService, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private formulaService: FormulaService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.formula = data.formula;
    }

    deleteFormula(): void {
        this.formulaService.deleteFormula(this.formula.id).subscribe((res) => {
            this.snack.open('¡Formula Eliminada!', '', {duration: 2500});
            setTimeout(() => {
                this.location.back();
            }, 2550);
        }, (err) => {
            this.snack.open('No se pudo eliminar la formula. Inténtalo más tarde.', 'Cerrar');
        });
    }

}
