import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProviderService } from '../../services/provider/provider.service';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetailResolve implements Resolve<any> {

  constructor(private ps: ProviderService) { }

  resolve(route: ActivatedRouteSnapshot) {
      let id = +route.params['id'];
      return this.ps.getProvider(id).pipe(map(provider => provider)).pipe(first());
  }
}
