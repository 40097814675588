import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ApplicationComponent } from './application/application.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductComponent } from './product/product.component';
import { ProductCreateComponent } from './product-create/product-create.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductBatchComponent } from './product-batch/product-batch.component';
import { OrderComponent } from './order/order.component';
import { OrderSearchComponent } from './order-search/order-search.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderReturnComponent } from './order-return/order-return.component';
import { OrderExecuteComponent } from './order-execute/order-execute.component';
import { CompositeComponent } from './composite/composite.component';
import { CompositeCreateComponent } from './composite-create/composite-create.component';
import { CompositeDetailComponent } from './composite-detail/composite-detail.component';
import { RequestComponent } from './request/request.component';
import { RequestCreateComponent } from './request-create/request-create.component';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { RequestEditComponent } from './request-edit/request-edit.component';
import { FormulaComponent } from './formula/formula.component';
import { FormulaCreateComponent } from './formula-create/formula-create.component';
import { FormulaEditComponent } from './formula-edit/formula-edit.component';
import { FormulaDetailComponent } from './formula-detail/formula-detail.component';
import { ReportComponent } from './report/report.component';
import { ProviderCreateComponent } from './provider-create/provider-create.component';
import { ClientComponent } from './client/client.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ReportInventoryChartComponent } from './report-inventory-chart/report-inventory-chart.component';
import { ReportOrdersChartComponent } from './report-orders-chart/report-orders-chart.component';
import { ReportRequestsChartComponent } from './report-requests-chart/report-requests-chart.component';
import { ReportInventoryDetailComponent } from './report-inventory-detail/report-inventory-detail.component';
import { ReportOrdersDetailComponent } from './report-orders-detail/report-orders-detail.component';
import { ReportRequestsDetailComponent } from './report-requests-detail/report-requests-detail.component';
import { OrderGenerateLabelComponent } from './order-generate-label/order-generate-label.component';
import { ProviderComponent } from './provider/provider.component';
import { ProviderEditComponent } from './provider-edit/provider-edit.component';

import { DashboardResolve } from './resolves/dashboard/dashboard-resolve.service';
import { ProductResolve } from './resolves/product/product-resolve.service';
import { ProductAllResolve } from './resolves/product/product-all-resolve.service';
import { ProductDetailResolve } from './resolves/product/product-detail-resolve.service';
import { MeasurementUnitResolve } from './resolves/measurement-unit/measurement-unit-resolve.service';
import { ProductTypeResolve } from './resolves/product-type/product-type-resolve.service';
import { ProductFamilyResolve } from './resolves/product-family/product-family-resolve.service';
import { BatchResolve } from './resolves/batch/batch-resolve.service';
import { BatchDetailResolve } from './resolves/batch/batch-detail-resolve.service';
import { OrderResolve } from './resolves/order/order-resolve.service';
import { OrderStatesResolve } from './resolves/order/order-states-resolve.service';
import { OrderDetailResolve } from './resolves/order/order-detail-resolve.service';
import { RequestResolve } from './resolves/request/request-resolve.service';
import { RequestStatesResolve } from './resolves/request/request-states-resolve.service';
import { RequestDetailResolve } from './resolves/request/request-detail-resolve.service';
import { CompositeResolve } from './resolves/composite/composite-resolve.service';
import { CompositeDetailResolve } from './resolves/composite/composite-detail-resolve.service';
import { ProductBatchResolve } from './resolves/product/product-batch-resolve.service';
import { ClientResolve } from './resolves/client/client-resolve.service';
import { ClientDetailResolve } from './resolves/client/client-detail-resolve.service';
import { ProviderResolve } from './resolves/provider/provider-resolve.service';
import { FormulaService } from './services/formula/formula.service';
import { FormulaResolve } from './resolves/formula/formula-resolve.service';
import { FormulaDetailResolve } from './resolves/formula/formula-detail-resolve.service';
import { WarehouseResolve } from './resolves/warehouse/warehouse-resolve.service';
import { ReportResolve } from './resolves/report/report-resolve.service';
import { ReportInventoryChartResolve } from './resolves/report/report-inventory-chart-resolve.service';
import { ReportOrdersChartResolve } from './resolves/report/report-orders-chart-resolve.service';
import { ReportRequestsChartResolve } from './resolves/report/report-requests-chart-resolve.service';
import { ReportInventoryDetailResolve } from './resolves/report/report-inventory-detail-resolve.service';
import { ReportOrdersDetailResolve } from './resolves/report/report-orders-detail-resolve.service';
import { ReportRequestsDetailResolve } from './resolves/report/report-requests-detail-resolve.service';
import { ProviderDetailResolve } from './resolves/provider/provider-detail-resolve.service';

import { AuthGuard } from './guards/auth.guard';
import { OutGuard } from './guards/out.guard';

const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, canActivate: [OutGuard]},
    {path: 'app', component: ApplicationComponent, children: [
        {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
        {path: 'product', component: ProductComponent, canActivate: [AuthGuard]},
        {path: 'product/create', component: ProductCreateComponent, canActivate: [AuthGuard], resolve: {units: MeasurementUnitResolve, types: ProductTypeResolve, families: ProductFamilyResolve}},
        {path: 'product/edit/:id', component: ProductEditComponent, canActivate: [AuthGuard], resolve: {product: ProductDetailResolve, units: MeasurementUnitResolve, types: ProductTypeResolve, families: ProductFamilyResolve}},
        {path: 'product/:id', component: ProductDetailComponent, canActivate: [AuthGuard], resolve: { product: ProductDetailResolve}},
        {path: 'product/:pid/batch/:bid', component: ProductBatchComponent, canActivate: [AuthGuard], resolve: {batch: ProductBatchResolve}},
        {path: 'order', component: OrderComponent, canActivate: [AuthGuard], resolve: {states: OrderStatesResolve, clients: ClientResolve}},
        {path: 'order/search', component: OrderSearchComponent, canActivate: [AuthGuard], resolve: {states: OrderStatesResolve, clients: ClientResolve, products: ProductAllResolve}},
        {path: 'order/create', component: OrderCreateComponent, canActivate: [AuthGuard], resolve: {products: ProductAllResolve, clients: ClientResolve}},
        {path: 'order/edit/:id', component: OrderEditComponent, canActivate: [AuthGuard], resolve: {order: OrderDetailResolve, products: ProductAllResolve, clients: ClientResolve}},
        {path: 'order/return/:id', component: OrderReturnComponent, canActivate: [AuthGuard], resolve: {order: OrderDetailResolve}},
        {path: 'order/execute/:id', component: OrderExecuteComponent, canActivate: [AuthGuard], resolve: {order: OrderDetailResolve}},
        {path: 'order/generate-labels/:id', component: OrderGenerateLabelComponent, canActivate: [AuthGuard], resolve: {order: OrderDetailResolve}},
        {path: 'order/:id', component: OrderDetailComponent, canActivate: [AuthGuard], resolve: {order: OrderDetailResolve}},
        {path: 'request', component: RequestComponent, canActivate: [AuthGuard], resolve: {states: RequestStatesResolve, providers: ProviderResolve}},
        {path: 'request/create', component: RequestCreateComponent, canActivate: [AuthGuard], resolve: {products: ProductAllResolve, providers: ProviderResolve}},
        {path: 'request/edit/:id', component: RequestEditComponent, canActivate: [AuthGuard], resolve: {request: RequestDetailResolve, products: ProductAllResolve, providers: ProviderResolve}},
        {path: 'request/:id', component: RequestDetailComponent, canActivate: [AuthGuard], resolve: {request: RequestDetailResolve}},
        {path: 'composite', component: CompositeComponent, canActivate: [AuthGuard]},
        {path: 'composite/create', component: CompositeCreateComponent, canActivate: [AuthGuard], resolve: {formulas: FormulaResolve}},
        {path: 'composite/:id', component: CompositeDetailComponent, canActivate: [AuthGuard], resolve: {composite: CompositeDetailResolve}},
        {path: 'formula', component: FormulaComponent, canActivate: [AuthGuard], resolve: {formulas: FormulaResolve}},
        {path: 'formula/create', component: FormulaCreateComponent, canActivate: [AuthGuard], resolve: {products: ProductAllResolve, units: MeasurementUnitResolve, types: ProductTypeResolve, families: ProductFamilyResolve}},
        {path: 'formula/edit/:id', component: FormulaEditComponent, canActivate: [AuthGuard], resolve: {formula: FormulaDetailResolve, products: ProductAllResolve, units: MeasurementUnitResolve, types: ProductTypeResolve, families: ProductFamilyResolve}},
        {path: 'formula/:id', component: FormulaDetailComponent, canActivate: [AuthGuard], resolve: {formula: FormulaDetailResolve}},
        {path: 'report', component: ReportComponent, canActivate: [AuthGuard], resolve: {types: ProductTypeResolve}},
        {path: 'report/chart/inventory', component: ReportInventoryChartComponent, canActivate: [AuthGuard], resolve: {charts: ReportInventoryChartResolve}},
        {path: 'report/chart/inventory/:id', component: ReportInventoryDetailComponent, canActivate: [AuthGuard], resolve: {product: ReportInventoryDetailResolve}},
        {path: 'report/chart/order', component: ReportOrdersChartComponent, canActivate: [AuthGuard], resolve: {charts: ReportOrdersChartResolve}},
        {path: 'report/chart/order/:id', component: ReportOrdersDetailComponent, canActivate: [AuthGuard], resolve: {product: ReportOrdersDetailResolve}},
        {path: 'report/chart/request', component: ReportRequestsChartComponent, canActivate: [AuthGuard], resolve: {charts: ReportRequestsChartResolve}},
        {path: 'report/chart/request/:id', component: ReportRequestsDetailComponent, canActivate: [AuthGuard], resolve: {product: ReportRequestsDetailResolve}},
        {path: 'client', component: ClientComponent, canActivate: [AuthGuard], resolve: {clients: ClientResolve}},
        {path: 'client/create', component: ClientCreateComponent, canActivate: [AuthGuard]},
        {path: 'client/edit/:id', component: ClientEditComponent, canActivate: [AuthGuard], resolve: {client: ClientDetailResolve}},
        {path: 'provider', component: ProviderComponent, canActivate: [AuthGuard]},
        {path: 'provider/create', component: ProviderCreateComponent, canActivate: [AuthGuard]},
        {path: 'provider/edit/:id', component: ProviderEditComponent, canActivate: [AuthGuard], resolve: {provider: ProviderDetailResolve}}
    ]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
