import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as moment from 'moment';

@Component({
    selector: 'report-orders-chart',
    templateUrl: './report-orders-chart.component.html'
})
export class ReportOrdersChartComponent implements OnInit {
    public title: string ='Gráficos Ventas';
    public purchases: any;
    public reactivos: any;
    public directos: any;
    public acidos: any;
    public dispersos: any;
    public auxiliares: any;

    /* AMOUNT */
    public amountReactivosChartData: any = [{data: [], label: "Inventario (KG)"}];
    public amountReactivosChartLabels: any = [];
    public amountReactivosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.decimal.transform(parseFloat(tooltipItem.yLabel), '1.2-2') + ' KG';
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.decimal.transform(value, '1.0-0') + ' KG';
                    },
                    min: 0
                }
            }]
        }
    };
    public amountReactivosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public amountReactivosChartType: string = 'bar';

    public amountDirectosChartData: any = [{data: [], label: "Inventario (KG)"}];
    public amountDirectosChartLabels: any = [];
    public amountDirectosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.decimal.transform(parseFloat(tooltipItem.yLabel), '1.2-2') + ' KG';
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.decimal.transform(value, '1.0-0') + ' KG';
                    },
                    min: 0
                }
            }]
        }
    };
    public amountDirectosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public amountDirectosChartType: string = 'bar';

    public amountAcidosChartData: any = [{data: [], label: "Inventario (KG)"}];
    public amountAcidosChartLabels: any = [];
    public amountAcidosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.decimal.transform(parseFloat(tooltipItem.yLabel), '1.2-2') + ' KG';
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.decimal.transform(value, '1.0-0') + ' KG';
                    },
                    min: 0
                }
            }]
        }
    };
    public amountAcidosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public amountAcidosChartType: string = 'bar';

    public amountDispersosChartData: any = [{data: [], label: "Inventario (KG)"}];
    public amountDispersosChartLabels: any = [];
    public amountDispersosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.decimal.transform(parseFloat(tooltipItem.yLabel), '1.2-2') + ' KG';
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.decimal.transform(value, '1.0-0') + ' KG';
                    },
                    min: 0
                }
            }]
        }
    };
    public amountDispersosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public amountDispersosChartType: string = 'bar';

    public amountAuxiliaresChartData: any = [{data: [], label: "Inventario (KG)"}];
    public amountAuxiliaresChartLabels: any = [];
    public amountAuxiliaresChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.decimal.transform(parseFloat(tooltipItem.yLabel), '1.2-2') + ' KG';
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.decimal.transform(value, '1.0-0') + ' KG';
                    },
                    min: 0
                }
            }]
        }
    };
    public amountAuxiliaresColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public amountAuxiliaresChartType: string = 'bar';

    /* TOTAL */
    public totalReactivosChartData: any = [{data: [], label: "Inventario (MX$)"}];
    public totalReactivosChartLabels: any = [];
    public totalReactivosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.currency.transform(tooltipItem.yLabel, 'MXN', 'symbol', '1.2-2');
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.currency.transform(value, 'MXN', 'symbol', '1.0-0');
                    },
                    min: 0
                }
            }]
        }
    };
    public totalReactivosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public totalReactivosChartType: string = 'bar';

    public totalDirectosChartData: any = [{data: [], label: "Inventario (MX$)"}];
    public totalDirectosChartLabels: any = [];
    public totalDirectosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.currency.transform(tooltipItem.yLabel, 'MXN', 'symbol', '1.2-2');
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.currency.transform(value, 'MXN', 'symbol', '1.0-0');
                    },
                    min: 0
                }
            }]
        }
    };
    public totalDirectosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public totalDirectosChartType: string = 'bar';

    public totalAcidosChartData: any = [{data: [], label: "Inventario (MX$)"}];
    public totalAcidosChartLabels: any = [];
    public totalAcidosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.currency.transform(tooltipItem.yLabel, 'MXN', 'symbol', '1.2-2');
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.currency.transform(value, 'MXN', 'symbol', '1.0-0');
                    },
                    min: 0
                }
            }]
        }
    };
    public totalAcidosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public totalAcidosChartType: string = 'bar';

    public totalDispersosChartData: any = [{data: [], label: "Inventario (MX$)"}];
    public totalDispersosChartLabels: any = [];
    public totalDispersosChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.currency.transform(tooltipItem.yLabel, 'MXN', 'symbol', '1.2-2');
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.currency.transform(value, 'MXN', 'symbol', '1.0-0');
                    },
                    min: 0
                }
            }]
        }
    };
    public totalDispersosColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public totalDispersosChartType: string = 'bar';

    public totalAuxiliaresChartData: any = [{data: [], label: "Inventario (MX$)"}];
    public totalAuxiliaresChartLabels: any = [];
    public totalAuxiliaresChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.currency.transform(tooltipItem.yLabel, 'MXN', 'symbol', '1.2-2');
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.currency.transform(value, 'MXN', 'symbol', '1.0-0');
                    },
                    min: 0
                }
            }]
        }
    };
    public totalAuxiliaresColorOptions: any = [{
        backgroundColor: [],
        borderColor: []
    }];
    public totalAuxiliaresChartType: string = 'bar';
    public from: any;
    public to: any;
    public start_date: any;
    public end_date: any;

    constructor(private router: Router, private route: ActivatedRoute, private currency: CurrencyPipe, private decimal: DecimalPipe) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.reactivos = data.charts.reactivos;
        this.directos = data.charts.directos;
        this.acidos = data.charts.acidos;
        this.dispersos = data.charts.dispersos;
        this.auxiliares = data.charts.auxiliares;
        moment.locale('es');
        this.route.queryParams.subscribe(params => {
            this.from = params['from'];
            this.to = params['to'];
            this.start_date = moment(params['from'], moment.ISO_8601).format('DD/MM/YY');
            this.end_date = moment(params['to'], moment.ISO_8601).format('DD/MM/YY');
        });

        this.reactivos.forEach(function(product, i) {
            if(product.order_products.length > 0) {
                this.amountReactivosChartData[0].data.push(product.total_amount);
                this.amountReactivosChartLabels.push(product.name);
                this.amountReactivosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.amountReactivosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
                this.totalReactivosChartData[0].data.push(product.total_price);
                this.totalReactivosChartLabels.push(product.name);
                this.totalReactivosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.totalReactivosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
            }
        }, this);

        this.directos.forEach(function(product, i) {
            if(product.order_products.length > 0) {
                this.amountDirectosChartData[0].data.push(product.total_amount);
                this.amountDirectosChartLabels.push(product.name);
                this.amountDirectosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.amountDirectosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
                this.totalDirectosChartData[0].data.push(product.total_price);
                this.totalDirectosChartLabels.push(product.name);
                this.totalDirectosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.totalDirectosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
            }
        }, this);

        this.acidos.forEach(function(product, i) {
            if(product.order_products.length > 0) {
                this.amountAcidosChartData[0].data.push(product.total_amount);
                this.amountAcidosChartLabels.push(product.name);
                this.amountAcidosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.amountAcidosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
                this.totalAcidosChartData[0].data.push(product.total_price);
                this.totalAcidosChartLabels.push(product.name);
                this.totalAcidosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.totalAcidosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
            }
        }, this);

        this.dispersos.forEach(function(product, i) {
            if(product.order_products.length > 0) {
                this.amountDispersosChartData[0].data.push(product.total_amount);
                this.amountDispersosChartLabels.push(product.name);
                this.amountDispersosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.amountDispersosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
                this.totalDispersosChartData[0].data.push(product.total_price);
                this.totalDispersosChartLabels.push(product.name);
                this.totalDispersosColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.totalDispersosColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
            }
        }, this);

        this.auxiliares.forEach(function(product, i) {
            if(product.order_products.length > 0) {
                this.amountAuxiliaresChartData[0].data.push(product.total_amount);
                this.amountAuxiliaresChartLabels.push(product.name);
                this.amountAuxiliaresColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.amountAuxiliaresColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
                this.totalAuxiliaresChartData[0].data.push(product.total_price);
                this.totalAuxiliaresChartLabels.push(product.name);
                this.totalAuxiliaresColorOptions[0].backgroundColor.push('rgba(' + product.color + ', 0.8)');
                this.totalAuxiliaresColorOptions[0].borderColor.push('rgba(' + product.color + ', 1)');
            }
        }, this);
    }

}
