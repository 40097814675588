import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { API } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    private url: string = API + '/order';

    constructor(private http: HttpClient) { }

    encodeQuery(params: any): string {
        return Object.keys(params).map((key) => {
            return [key, params[key]].map(encodeURIComponent).join('=');
        }).join('&');
    }

    getOrders(params?: any): Observable<any> {
        let query = '';
        if (params) {
            query += '?';
            query += this.encodeQuery(params);
        }
        return this.http.get(`${this.url}${query}`);
    }

    searchOrders(params?: any): Observable<any> {
        let query = '';
        if(params) {
            query += '?';
            query += this.encodeQuery(params);
        }
        return this.http.get(`${this.url}/search${query}`);
    }

    getOrder(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}`);
    }

    createOrder(order: any): Observable<any> {
        return this.http.post(this.url, order);
    }

    updateOrder(id: number, order: any): Observable<any> {
        return this.http.put(`${this.url}/${id}`, order);
    }

    completeOrder(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/ship`);
    }

    executeOrder(id: number, containers: any): Observable<any> {
        return this.http.put(`${this.url}/${id}/execute`, containers);
    }

    deleteOrder(id: number): Observable<any> {
        return this.http.delete(`${this.url}/${id}`);
    }

    generateOrderLabels(id: number, data: any): Observable<any> {
        return this.http.put(`${this.url}/${id}/generate-labels`, data);
    }

    returnOrder(id: number, order: any): Observable<any> {
        return this.http.put(`${this.url}/${id}/return`, order);
    }

    getStates(): Observable<any> {
        return this.http.get(`${this.url}/state`);
    }
}
