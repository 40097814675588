import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session/session.service';
import { ReportService } from '../services/report/report.service';
import { MatSnackBar } from '@angular/material';
import { SERVER } from '../server';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
    selector: 'report',
    templateUrl: './report.component.html'
})
export class ReportComponent implements OnInit {
    public title: string = 'Reportes';
    public products: any;
    public server: string = SERVER;
    public token: string;
    public selectedReport: string;
    public reportTypes: string[] = ['Inventario', 'Ventas', 'Compras', 'Inventario Bajo'];
    public types: Array<any>;
    public selectedType: any = null;
    public from: any;
    public to: any;
    public orders: any;
    public requests: any;
    public inventory: any;
    public lowInventory: any;
    public loading: boolean = false;

    constructor(private route: ActivatedRoute, private session: SessionService, private reportService: ReportService, private snack: MatSnackBar) {
        this.token = session.getToken();
    }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.types = data.types;
        moment.locale('es');
        let month_ago = moment().startOf('month').subtract(1, 'months').toDate();
        this.route.queryParams.subscribe(params => {
            this.from = (params['from']) ? params['from'] : month_ago.toISOString();
            this.to = (params['to']) ? params['to'] : moment().endOf('day').toDate().toISOString();
            this.selectedReport = (params['report']) ? params['report'] : 'Inventario';
        });
    }

    compare(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    fromDate(event: MatDatepickerInputEvent<Date>) {
        let from_date = event.value.toISOString();
        this.from = from_date;
    }

    toDate(event: MatDatepickerInputEvent<Date>) {
        let to_date = moment(event.value).toDate().toISOString();
        this.to = to_date;
    }

    generateReport(): void {
        this.loading = true;
        this.orders = null;
        this.requests = null;
        this.inventory = null;
        this.lowInventory = null;

        switch(this.selectedReport) {
            case 'Inventario': {
                this.getInventoryReport();
                break;
            }
            case 'Ventas':  {
                this.getOrdersReport();
                break;
            }
            case 'Compras': {
                this.getRequestsReport();
                break;
            }
            case 'Inventario Bajo': {
                this.getLowInventoryReport();
                break;
            }
            default: {
                break;
            }
        }
    }

    getInventoryReport(): void {
        this.reportService.getInventory().subscribe(data => {
            this.inventory = data;
            this.loading = false;
        }, err => {
            this.loading = false;
        });
    }

    getOrdersReport(): void {
        this.reportService.getOrders(this.from, this.to).subscribe(data => {
            this.orders = data;
            this.loading = false;
        }, err => {
            this.loading = false;
        });
    }

    getRequestsReport(): void {
        this.reportService.getRequests(this.from, this.to).subscribe(data => {
            this.requests = data;
            this.loading = false;
        }, err => {
            this.loading = false;
        });
    }

    getLowInventoryReport(): void {
        this.reportService.getLowInventory(this.selectedType.id).subscribe(data => {
            this.lowInventory = data;
            this.loading = false;
            console.log(data);
        }, err => {
            this.loading = false;
        });
    }

}
