import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { OrderService } from '../services/order/order.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
    selector: 'order-execute',
    templateUrl: './order-execute.component.html'
})
export class OrderExecuteComponent implements OnInit {
    public title: string = 'Ejecutar Venta';
    public order: any;
    public orderForm: FormGroup;
    public orderContainers: Array<FormGroup> = [];
    public orderContainersFormArray: FormArray = new FormArray(this.orderContainers);
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private orderService: OrderService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, public dialog: MatDialog) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.order = data.order;

        this.orderForm = this.fb.group({
            containers: this.orderContainersFormArray
        });

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.client_id = (params.client_id) ? params.client_id : '0';
        });
    }

    addOrderContainer(): void {
        this.orderContainersFormArray.push(new FormGroup({
            box: new FormControl(null, Validators.required),
            amount: new FormControl(0, [Validators.required, Validators.min(0.1)])
        }));
    }

    removeOrderContainer(i: number): void {
        this.orderContainersFormArray.removeAt(i);
    }

    handler(): void {
        if(this.orderForm.valid) {
            this.loading = true;
            this.orderService.executeOrder(+this.order.id, this.orderForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Venta Ejecutada!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/order/' + this.order.id);}, 2600);
            }, (err) => {
                this.loading = false;
                let info = err.error;
                const dialogRef = this.dialog.open(ErrorDialogComponent, {
                    data: {object: info.object, message: info.message, type: info.type, amount: info.amount}
                });
            });
        }
    }

}
