import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { CompositeService } from '../services/composite/composite.service';
import { SessionService } from '../services/session/session.service';
import { SERVER } from '../server';

@Component({
    selector: 'composite-detail',
    templateUrl: './composite-detail.component.html'
})
export class CompositeDetailComponent implements OnInit {
    public composite: any;
    public server: string = SERVER;
    public params: any = {};

    constructor(public location: Location, private route: ActivatedRoute, private router: Router, private snack: MatSnackBar, private compositeService: CompositeService, public session: SessionService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.composite = data.composite;
        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
    }

    deleteComposite(): void {
        this.compositeService.deleteComposite(this.composite.id).subscribe((res) => {
            this.snack.open('¡Solicitud de Compuesto Eliminada!', '', { duration: 2500 });
            setTimeout(() => {
                this.router.navigateByUrl('/app/composite');
            }, 2550);
        }, (err) => {
            this.snack.open('No se pudo eliminar la solicitud de compuesto. Inténtalo más tarde.', 'Cerrar');
        });
    }

}
