import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'error-dialog',
    template: `<h1 *ngIf="data.type == 'product'" mat-dialog-title>Producto: {{data.object}} <span *ngIf="data.amount != 0">({{data.amount}} KG)</span></h1>
        <h1 *ngIf="data.type == 'container'" mat-dialog-title>Caja: #{{data.object}} <span *ngIf="data.amount != 0">({{data.amount}} KG)</span></h1>
        <div mat-dialog-content>
          <p>{{data.message}}</p>
        </div>
        <div mat-dialog-actions>
          <button mat-raised-button color="primary" (click)="close()" cdkFocusInitial>Cerrar</button>
        </div>`
})
export class ErrorDialogComponent {

    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    close(): void {
        this.dialogRef.close();
    }

}
