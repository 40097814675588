import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RequestService } from '../../services/request/request.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RequestDetailResolve implements Resolve<any> {

    constructor(private rs: RequestService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = +route.params['id'];

        return this.rs.getRequest(id).pipe(map(request => request)).pipe(first());
    }

}
