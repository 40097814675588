import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from '../../services/product/product.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductBatchResolve implements Resolve<any> {

    constructor(private ps: ProductService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let pid = +route.params['pid'];
        let bid = +route.params['bid'];

        return this.ps.getBatch(pid, bid).pipe(map(batch => batch)).pipe(first());
    }

}
