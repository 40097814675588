import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProductTypeService } from '../../services/product-type/product-type.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductTypeResolve implements Resolve<any> {

    constructor(private pts: ProductTypeService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.pts.getProductTypes().pipe(map(types => types)).pipe(first());
    }
}
