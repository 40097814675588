import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { OrderService } from '../../services/order/order.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrderDetailResolve implements Resolve<any> {

    constructor(private os: OrderService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = +route.params['id'];

        return this.os.getOrder(id).pipe(map(order => order)).pipe(first());
    }

}
