import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class ProviderService {
    private url: string = API + '/provider';

    constructor(private http: HttpClient) { }

    encodeQuery(params: any): string {
        return Object.keys(params).map((key) => {
            return [key, params[key]].map(encodeURIComponent).join('=');
        }).join('&');
    }

    getProviders(params?: any): Observable<any> {
        let query = '';
        if(params) {
            query += '?';
            query += this.encodeQuery(params);
        }
        return this.http.get(`${this.url}${query}`);
    }

    allProviders(): Observable<any> {
        return this.http.get(`${this.url}/all`);
    }

    getProvider(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}`);
    }

    createProvider(provider: any): Observable<any> {
        return this.http.post(this.url, provider);
    }

    updateProvider(id: number, client: any): Observable<any> {
        return this.http.put(`${this.url}/${id}`, client);
    }
}
