import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ReportService } from '../../services/report/report.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportInventoryChartResolve implements Resolve<any> {

    constructor(private rs: ReportService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.rs.getInventoryChart().pipe(map(charts => charts)).pipe(first());
    }

}
