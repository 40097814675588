import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from '../services/auth/auth.service';
import { SessionService } from '../services/session/session.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app',
    templateUrl: './application.component.html'
})
export class ApplicationComponent implements OnInit {
    public title: string = 'Anilpan';
    public layoutChanges: Observable<any>;
    public mode: string = 'side';
    public open: boolean = true;
    public mobile: boolean = false;
    public loading: boolean;

    constructor(private router: Router, private breakpointObserver: BreakpointObserver, private mediaMatcher: MediaMatcher, private auth: AuthService, private session: SessionService) { }

    ngOnInit(): void {
        //this.loading = true;
        this.layoutChanges = this.breakpointObserver.observe([
            Breakpoints.Web
        ]);

        this.layoutChanges.subscribe(result => {
            this.updateLayout();
        });
    }

    ngAfterViewInit() {
        this.router.events.subscribe((event) => {
            if(event instanceof NavigationStart) {
                this.loading = true;
            } else if (event instanceof NavigationEnd ||  event instanceof NavigationCancel) {
                this.loading = false;
            }
        });
    }

    updateLayout(): void {
        let mediaQueryList = this.mediaMatcher.matchMedia('(min-width: 1200px)');
        if (mediaQueryList.matches) {
            this.open = true;
            this.mobile = false;
            this.mode = 'side';
        } else {
            this.open = false;
            this.mobile = true;
            this.mode = 'over';
        }
    }

    logout(): void {
        this.auth.logout();
        this.router.navigateByUrl('login');
    }

}
