import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as moment from 'moment';

@Component({
    selector: 'report-requests-detail',
    templateUrl: './report-requests-detail.component.html'
})
export class ReportRequestsDetailComponent implements OnInit {
    public product: any;
    public amountChartData: any = [{data: [], label: ""}];
    public amountChartLabels: any = [];
    public amountChartOptions: any = {
        tooltips: {callbacks: {label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
                label += ': ';
            }
            label += this.decimal.transform(parseFloat(tooltipItem.yLabel), '1.2-2') + ' KG';
            return label;
        }}},
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return this.decimal.transform(value, '1.0-0') + ' KG';
                    },
                    min: 0
                }
            }]
        }
    };
    public amountColorOptions: any = [{
        backgroundColor: '',
        borderColor: ''
    }];
    public amountChartType: string = 'bar';
    public from: any;
    public to: any;
    public start_date: any;
    public end_date: any;

    constructor(private router: Router, private route: ActivatedRoute, private currency: CurrencyPipe, private decimal: DecimalPipe) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.product = data.product;
        this.amountChartData[0].label = "Compras (KG)";
        this.amountColorOptions[0].backgroundColor = 'rgba(' + this.product.color + ', 0.8)';
        this.amountColorOptions[0].borderColor = 'rgba(' + this.product.color + ', 1)';

        moment.locale('es');
        this.route.queryParams.subscribe(params => {
            this.from = params['from'];
            this.to = params['to'];
            this.start_date = moment(params['from'], moment.ISO_8601).format('DD/MM/YY');
            this.end_date = moment(params['to'], moment.ISO_8601).format('DD/MM/YY');
        });

        this.product.batch_product_requests.forEach(function(batch_product_request, i) {
            this.amountChartData[0].data.push(batch_product_request.amount);
            this.amountChartLabels.push(moment(batch_product_request.request.arrived_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY'));
        }, this);
    }

}
