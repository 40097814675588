import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ClientService } from '../../services/client/client.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ClientDetailResolve implements Resolve<any> {

    constructor(private cs: ClientService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = +route.params['id'];
        return this.cs.getClient(id).pipe(map(client => client)).pipe(first());
    }

}
