import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], term): any {
        return term ? items.filter(item => {
            let name = item.product.name.toLowerCase();
            return name.indexOf(term.toLowerCase()) !== -1
        }) : items;
    }

}
