import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../services/session/session.service';
import { OrderService } from '../services/order/order.service';
import { SERVER } from '../server';

@Component({
    selector: 'order-detail',
    templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent implements OnInit {
    public data: any;
    public order: any;
    public server: string = SERVER;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private orderService: OrderService, private route: ActivatedRoute, private router: Router, public session: SessionService) { }

    ngOnInit() {
        this.data = this.route.snapshot.data;
        this.order = this.data.order;
        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.client_id = (params.client_id) ? params.client_id : '0';
        });
    }

    completeOrder(): void {
        this.orderService.completeOrder(+this.order.id).subscribe((res) => {
            this.snack.open('¡Venta Completada!', '', {duration: 2500});
            this.order.state_id = 2;
        }, (err) => {
            this.snack.open('No se pudo completar la venta. Inténtalo más tarde.', 'Cerrar');
        });
    }

    deleteOrder(): void {
        this.orderService.deleteOrder(this.order.id).subscribe((res) => {
            this.snack.open('¡Venta Eliminada!', '', {duration: 2500});
            setTimeout(() => {
                this.router.navigateByUrl('/app/order');
            }, 2550);
        }, (err) => {
            this.snack.open('No se pudo eliminar la venta. Inténtalo más tarde.', 'Cerrar');
        });
    }

}
