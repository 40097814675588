import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class BatchService {
    private url: string = API + '/batch';

    constructor(private http: HttpClient) { }

    getBatches(): Observable<any> {
        return this.http.get(this.url);
    }

    getBatch(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}`);
    }

    createBatch(batch: any): Observable<any> {
        return this.http.post(this.url, batch);
    }

    approveBatch(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/approve`);
    }

    amountContainer(id: number, data: any): Observable<any> {
        return this.http.put(API + '/container/' + id + '/amount', data);
    }

    unifyContainers(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/unify-containers`);
    }
}
