import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class DashboardResolve implements Resolve<any> {

    constructor(private ds: DashboardService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.ds.getDashboard().pipe(map(dashboard => dashboard)).pipe(first());
    }

}
