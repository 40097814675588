import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from '../../services/product/product.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductAllResolve implements Resolve<any> {

    constructor(private ps: ProductService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.ps.allProducts().pipe(map(products => products)).pipe(first());
    }

}
