import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, merge, fromEvent, of as observableOf } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../services/session/session.service';
import { RequestService } from '../services/request/request.service';
import { RequestsDatabase } from '../databases/requests-database';
//import { RequestsDataSource } from '../datasources/requests-data-source';
import { debounceTime, distinctUntilChanged, catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'request',
    templateUrl: './request.component.html'
})
export class RequestComponent implements OnInit {
    public title: string = 'Compras';
    public requestsColumns = ['id', 'customs_number', 'provider', 'created_at', 'arrived_at', 'total', 'state'];
    public requests: any;
    public states: any;
    public providers: any;
    public selectedState: any = '0';
    public selectedProvider: any = '0';
    public params: any = {};
    public refreshing: boolean = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filter') filter: ElementRef;
    public resultsLength = 0;

    constructor(private route: ActivatedRoute, private router: Router, public session: SessionService, private requestService: RequestService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.states = data.states;
        this.providers = data.providers;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(debounceTime(300))
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.searchRequests();
            });
        this.route.queryParams.subscribe(params => {
            this.paginator.pageIndex = this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            if(this.selectedState != params.state_id) {
                this.selectedState = (params.state_id) ? params.state_id : '0';
            }
            if(this.selectedProvider != params.provider_id) {
                this.selectedProvider = (params.provider_id) ? params.provider_id : '0';
            }
        });
        this.loadRequests();
    }

    compare(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    stateChange() {
        this.searchRequests();
    }

    providerChange() {
        this.searchRequests();
    }

    refresh() {
        this.searchRequests();
    }

    searchRequests() {
        this.refreshing = true;
        this.params.page = this.paginator.pageIndex = 0;
        this.params.s = this.filter.nativeElement.value;
        this.params.state_id = this.selectedState;
        this.params.provider_id = this.selectedProvider;
        this.params = (this.params.s) ? {page: this.params.page, s: this.params.s, state_id: this.selectedState, provider_id: this.selectedProvider} : {page: this.params.page, state_id: this.selectedState, provider_id: this.selectedProvider};
        this.requestService.getRequests(this.params).subscribe(data => {
            this.refreshing = false;
            this.resultsLength = data.total_count;
            this.requests = data.items;
            this.requests = data.items;
            this.router.navigate(['/app/request'], {queryParams: this.params});
        });
    }

    loadRequests() {
        this.paginator.page.pipe(
            startWith({}),
            switchMap(() => {
                this.refreshing = true;
                this.params.page = this.paginator.pageIndex;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s, state_id: this.selectedState, provider_id: this.selectedProvider} : {page: this.params.page, state_id: this.selectedState, provider_id: this.selectedProvider};
                return this.requestService!.getRequests(this.params);
            }),
            map(data => {
                this.refreshing = false;
                this.resultsLength = data.total_count;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s, state_id: this.selectedState, provider_id: this.selectedProvider} : {page: this.params.page, state_id: this.selectedState, provider_id: this.selectedProvider};
                this.requests = data.items;this.router.navigate(['/app/request'], {queryParams: this.params});
                return data.items;
            }),
            catchError(() => {
                this.refreshing = false;
                console.log('data retrieve error');
                return observableOf([]);
            })
        ).subscribe(data => this.requests = data);
    }

}
