import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ProductService } from '../services/product/product.service';

@Component({
    selector: 'product-edit',
    templateUrl: './product-edit.component.html'
})
export class ProductEditComponent implements OnInit {
    public title: string = 'Editar Producto';
    public product: FormGroup;
    public p: any;
    public types: any;
    public units: any;
    public families: any;
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private fb: FormBuilder, private ps: ProductService, private route: ActivatedRoute, private router: Router, private snack: MatSnackBar) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.p = data.product;
        this.units = data.units;
        this.types = data.types;
        this.families = data.families;
        this.product = this.fb.group({
            name: new FormControl(this.p.name),
            name_origin: new FormControl(this.p.name_origin),
            description: new FormControl(this.p.description),
            minimum: new FormControl(this.p.minimum),
            unit_price: new FormControl(this.p.unit_price),
            measurement_unit: new FormControl(this.p.measurement_unit),
            product_type: new FormControl(this.p.product_type),
            family: new FormControl(this.p.family),
            is_composite: new FormControl(this.p.is_composite)
        });
        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
    }

    handler(): void {
        if(this.product.valid) {
            this.loading = true;
            this.ps.updateProduct(this.p.id, this.product.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Producto Guardado!', '', {duration: 2500});
                setTimeout(() => {this.location.back();}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar el producto. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

    compare(o1: any, o2: any) {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

}
