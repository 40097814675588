import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'formula',
    templateUrl: './formula.component.html'
})
export class FormulaComponent implements OnInit {
    public title: string = 'Formulas';
    public formulas: MatTableDataSource<any>;
    public formulasColumns: string[] = ['name'];
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.formulas = new MatTableDataSource(data.formulas);
        this.formulas.paginator = this.paginator;
        this.formulas.filterPredicate = (data: any, filter: string) => data.product.name.toLowerCase().indexOf(filter) !== -1;
    }

    applyFilter(value: string) {
        this.formulas.filter = value.trim().toLowerCase();

        if(this.formulas.paginator) {
            this.formulas.paginator.firstPage();
        }
    }

}
