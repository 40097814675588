import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'client',
    templateUrl: './client.component.html'
})
export class ClientComponent implements OnInit {
    public title: string = 'Clientes';
    public clients: any;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.clients = data.clients;
    }

}
