import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../services/session/session.service';
import { RequestService } from '../services/request/request.service';
import { SERVER } from '../server';

@Component({
    selector: 'request-detail',
    templateUrl: './request-detail.component.html'
})
export class RequestDetailComponent implements OnInit {
    public request: any;
    public server: string = SERVER;
    public params: any = {};

    constructor(public location: Location, private route: ActivatedRoute, public router: Router, public session: SessionService, private requestService: RequestService, private snack: MatSnackBar) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.request = data.request;
        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.provider_id = (params.provider_id) ? params.provider_id : '0';
        });
    }

    completeRequest() {
        this.requestService.completeRequest(+this.request.id).subscribe((res) => {
            this.snack.open('¡Compra Completada!', '', {duration: 2500});
            this.request.state_id = 1;
        }, (err) => {
            this.snack.open('No se pudo completar la compra. Inténtalo más tarde.', 'Cerrar');
        });
    }

    deleteRequest(): void {
        this.requestService.deleteRequest(this.request.id).subscribe((res) => {
            this.snack.open('¡Compra Eliminada!', '', {duration: 2500});
            setTimeout(() => {
                this.router.navigateByUrl('/app/request');
            }, 2550);
        }, (err) => {
            this.snack.open('No se pudo eliminar la compra. Inténtalo más tarde.', 'Cerrar');
        });
    }

}
