import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from '../../services/product/product.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductDetailResolve implements Resolve<any> {

    constructor(private ps: ProductService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = +route.params['id'];
        return this.ps.getProduct(id).pipe(map(product => product)).pipe(first());
    }

}
