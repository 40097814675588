import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, merge, fromEvent, of as observableOf } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../services/order/order.service';
import { OrdersDatabase } from '../databases/orders-database';
//import { OrdersDataSource } from '../datasources/orders-data-source';
import { debounceTime, distinctUntilChanged, catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'order',
    templateUrl: './order.component.html'
})
export class OrderComponent implements OnInit {
    public title: string = 'Ventas';
    public ordersColumns = ['id', 'code', 'client', 'created_at', 'delivered_at', 'total', 'state'];
    public orders: any;
    public states: any;
    public clients: any;
    public selectedState: any = '0';
    public selectedClient: any = '0';
    public params: any = {};
    public refreshing: boolean = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filter') filter: ElementRef;

    public resultsLength = 0;

    constructor(private route: ActivatedRoute, private router: Router, private orderService: OrderService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.states = data.states;
        this.clients = data.clients;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(debounceTime(300))
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.searchOrders();
            });
        this.route.queryParams.subscribe(params => {
            this.paginator.pageIndex = this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.selectedState = (params.state_id) ? params.state_id : '0';
            this.selectedClient = (params.client_id) ? params.client_id : '0';
        });
        this.loadOrders();
    }

    compare(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    stateChange() {
        this.searchOrders();
    }

    clientChange() {
        this.searchOrders();
    }

    refresh() {
        this.searchOrders();
    }

    searchOrders() {
        this.refreshing = true;
        this.params.page = this.paginator.pageIndex = 0;
        this.params.s = this.filter.nativeElement.value;
        this.params.state_id = this.selectedState;
        this.params.client_id = this.selectedClient;
        this.params = (this.params.s) ? {page: this.params.page, s: this.params.s, state_id: this.selectedState, client_id: this.selectedClient} : {page: this.params.page, state_id: this.selectedState, client_id: this.selectedClient};
        this.orderService.getOrders(this.params).subscribe(data => {
            this.refreshing = false;
            this.resultsLength = data.total_count;
            this.orders = data.items;
            this.router.navigate(['/app/order'], {queryParams: this.params});
        });
    }

    loadOrders() {
        this.paginator.page.pipe(
            startWith({}),
            switchMap(() => {
                this.refreshing = true;
                this.params.page = this.paginator.pageIndex;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s, state_id: this.selectedState, client_id: this.selectedClient} : {page: this.params.page, state_id: this.selectedState, client_id: this.selectedClient};
                return this.orderService!.getOrders(this.params);
            }),
            map(data => {
                this.refreshing = false;
                this.resultsLength = data.total_count;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s, state_id: this.selectedState, client_id: this.selectedClient} : {page: this.params.page, state_id: this.selectedState, client_id: this.selectedClient};
                this.router.navigate(['/app/order'], {queryParams: this.params});
                return data.items;
            }),
            catchError(() => {
                this.refreshing = false;
                console.log('data retrieve error');
                return observableOf([]);
            })
        ).subscribe(data => this.orders = data);
    }

}
