import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    constructor(private http: HttpClient) { }

    isAuthenticated(): boolean {
        let user = localStorage.getItem('user');
        if (!user) {
            return false;
        }
        return true;
    }

    getUser() {
        let user = JSON.parse(localStorage.getItem('user'));
        return user;
    }

    getOptions() {
        let user = this.getUser();
        return user.profile.options;
    }

    getRedirect() {
        let options = this.getOptions();
        return options['0'].url;
    }

    getToken() {
        let token = JSON.parse(localStorage.getItem('token'));
        return token;
    }

    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
        return user;
    }

    setToken(token) {
        localStorage.setItem('token', JSON.stringify(token));
        return token;
    }

    deleteUser() {
        localStorage.removeItem('user');
    }

    deleteToken() {
        localStorage.removeItem('token');
    }

    hasOption(option): boolean {
        let options = this.getOptions();
        return (options.find(o => o.url === option)) ? true : false;
    }
}
