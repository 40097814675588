import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Observable, merge, fromEvent, of as observableOf } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CompositesDatabase } from '../databases/composites-database';
import { CompositeService } from '../services/composite/composite.service';
//import { CompositesDataSource } from '../datasources/composites-data-source';
import { debounceTime, distinctUntilChanged, catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'composite',
    templateUrl: './composite.component.html'
})
export class CompositeComponent implements OnInit {
    public title: string = 'Solicitudes de Compuesto';
    public compositesColumns = ['id', 'composite', 'created_at', 'prepared_at', 'amount', 'state'];
    public composites: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filter') filter: ElementRef;
    public refreshing: boolean = true;
    public params: any = {};
    public resultsLength = 0;

    constructor(public location: Location, private route: ActivatedRoute, private router: Router, private compositeService: CompositeService) { }

    ngOnInit() {
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(debounceTime(300))
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.searchComposites();
            });
        this.route.queryParams.subscribe(params => {
            this.paginator.pageIndex = this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
        this.loadComposites();
    }

    searchComposites() {
        this.refreshing = true;
        this.params.page = this.paginator.pageIndex = 0;
        this.params.s = this.filter.nativeElement.value;
        this.params = (this.params.s) ? {page: this.params.page, s: this.params.s} : {page: this.params.page};
        this.compositeService.getComposites(this.params).subscribe(data => {
            this.refreshing = false;
            this.resultsLength = data.total_count;
            this.composites = data.items;
            this.router.navigate(['/app/composite'], {queryParams: this.params});
        });
    }

    loadComposites() {
        this.paginator.page.pipe(
            startWith({}),
            switchMap(() => {
                this.refreshing = true;
                this.params.page = this.paginator.pageIndex;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s} : {page: this.params.page};
                return this.compositeService!.getComposites(this.params);
            }),
            map(data => {
                this.refreshing = false;
                this.resultsLength = data.total_count;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s} : {page: this.params.page};
                this.router.navigate(['/app/composite'], {queryParams: this.params});
                return data.items;
            }),
            catchError(() => {
                this.refreshing = false;
                console.log('data retrieve error');
                return observableOf([]);
            })
        ).subscribe(data => this.composites = data);
    }

}
