import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class RequestService {
    private url: string = API + '/request';

    constructor(private http: HttpClient) { }

    encodeQuery(params: any): string {
        return Object.keys(params).map((key) => {
            return [key, params[key]].map(encodeURIComponent).join('=');
        }).join('&');
    }

    getRequests(params?: any): Observable<any> {
        let query = '';
        if (params) {
            query += '?';
            query += this.encodeQuery(params);
        }
        return this.http.get(`${this.url}${query}`);
    }

    getRequest(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}`);
    }

    createRequest(request: any): Observable<any> {
        return this.http.post(this.url, request);
    }

    updateRequest(id: number, request: any): Observable<any> {
        return this.http.put(`${this.url}/${id}`, request);
    }

    completeRequest(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/complete`);
    }

    getStates(): Observable<any> {
        return this.http.get(`${this.url}/state`);
    }

    deleteRequest(id: number): Observable<any> {
        return this.http.delete(`${this.url}/${id}`);
    }
}
