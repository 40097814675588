import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { OrderService } from '../../services/order/order.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrderStatesResolve implements Resolve<any> {

    constructor(private os: OrderService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.os.getStates().pipe(map(states => states)).pipe(first());
    }

}
