import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../services/session/session.service';
import { OrderService } from '../services/order/order.service';

@Component({
    selector: 'order-generate-label',
    templateUrl: './order-generate-label.component.html'
})
export class OrderGenerateLabelComponent implements OnInit {
    public title: string = 'Etiquetas de Salida';
    public order: any;
    public orderLabelsForm: FormGroup;
    public batchProductsBoxes: Array<FormGroup> = [];
    public batchProductsBoxesFormArray: FormArray = new FormArray(this.batchProductsBoxes);
    public boxes: Array<any> = [];
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private orderService: OrderService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.order = data.order;

        this.orderLabelsForm = this.fb.group({
            batch_products_boxes: this.batchProductsBoxesFormArray
        });

        this.order.batch_products.forEach((batch_product, i) => {
            this.boxes.push([]);
            let index = this.boxes.length - 1;
            this.batchProductsBoxesFormArray.push(new FormGroup({
                batch_product_order_id: new FormControl(batch_product.pivot.id),
                batch:  new FormControl(batch_product.batch.code),
                product: new FormControl(batch_product.product.name_origin),
                client: new FormControl(this.order.client.name),
                amount: new FormControl(batch_product.pivot.amount),
                boxes: new FormArray(this.boxes[index])
            }));
        }, this);

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.client_id = (params.client_id) ? params.client_id : '0';
        });
    }

    addBatchProductBox(batch_product): void {
        batch_product.controls['boxes'].push(new FormGroup({
            box_count: new FormControl(0, [Validators.required, Validators.min(1)]),
            amount_per_box: new FormControl(0, [Validators.required, Validators.min(0.1)])
        }));
    }

    removeBatchProductBox(product: any, j: number): void {
        product.controls['boxes'].removeAt(j);
    }

    handler(): void {
        if(this.orderLabelsForm.valid) {
            this.loading = true;
            this.orderService.generateOrderLabels(this.order.id, this.orderLabelsForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Etiquetas generadas!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/order/' + this.order.id);}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudieron generar las etiquetas. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
