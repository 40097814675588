import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, merge, fromEvent, of as observableOf } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../services/session/session.service'
import { ProductsDatabase } from '../databases/products-database';
import { ProductService } from '../services/product/product.service';
//import { ProductsDataSource } from '../datasources/products-data-source';
import { debounceTime, distinctUntilChanged, catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'product',
    templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {
    public title: string = 'Inventario';
    public productsColumns = ['id', 'name', 'type', 'amount', 'minimum'];
    public products: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filter') filter: ElementRef;
    public refreshing: boolean = true;
    public params: any = {};
    public resultsLength = 0;

    constructor(private route: ActivatedRoute, private router: Router, private session: SessionService, private location: Location, private ps: ProductService) { }

    ngOnInit() {
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(debounceTime(300))
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.searchInventory();
            });
        this.route.queryParams.subscribe(params => {
            this.paginator.pageIndex = this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
        this.loadInventory();
    }

    searchInventory() {
        this.refreshing = true;
        this.params.page = this.paginator.pageIndex = 0;
        this.params.s = this.filter.nativeElement.value;
        this.params = (this.params.s) ? {page: this.params.page, s: this.params.s} : {page: this.params.page};
        this.ps.getProducts(this.params).subscribe(data => {
            this.refreshing = false;
            this.resultsLength = data.total_count;
            this.products = data.items;
            this.router.navigate(['/app/product'], {queryParams: this.params});
        });
    }

    loadInventory() {
        this.paginator.page.pipe(
            startWith({}),
            switchMap(() => {
                this.refreshing = true;
                this.params.page = this.paginator.pageIndex;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s} : {page: this.params.page};
                return this.ps!.getProducts(this.params);
            }),
            map(data => {
                this.refreshing = false;
                this.resultsLength = data.total_count;
                this.params = (this.params.s) ? {page: this.params.page, s: this.params.s} : {page: this.params.page};
                this.router.navigate(['/app/product'], {queryParams: this.params});
                return data.items;
            }),
            catchError(() => {
                this.refreshing = false;
                console.log('data retrieve error');
                return observableOf([]);
            })
        ).subscribe(data => this.products = data);
    }

}
