import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'confirm-dialog',
    template: `<h1 mat-dialog-title>Eliminación de Producto</h1>
        <div mat-dialog-content>
          <p>Estás a punto de eliminar el producto. ¿Estás seguro que quiere eliminar este producto?</p>
        </div>
        <div mat-dialog-actions>
          <button mat-raised-button color="primary" [mat-dialog-close]="true">Sí</button>
          <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>No</button>
        </div>`
})
export class ConfirmDialogComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

}
