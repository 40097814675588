import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MeasurementUnitService } from '../../services/measurement-unit/measurement-unit.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MeasurementUnitResolve implements Resolve<any> {

    constructor(private mus: MeasurementUnitService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.mus.getMeasurementUnits().pipe(map(units => units)).pipe(first());

    }
}
