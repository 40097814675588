import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { OrderService } from '../services/order/order.service';
import * as moment from 'moment';

@Component({
    selector: 'app-order-edit',
    templateUrl: './order-edit.component.html'
})
export class OrderEditComponent implements OnInit {
    public title: string = 'Editar Venta';
    public clients: Array<any>;
    public filteredClients: Observable<any>;
    public products: Array<any>;
    public filteredProducts: Observable<any>;
    public order: any;
    public orderForm: FormGroup;
    public orderProducts: Array<FormGroup> = [];
    public orderProductsFormArray: FormArray = new FormArray(this.orderProducts);
    public globalTotal: number = 0;
    public globalTotalAmount: number = 0;
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private orderService: OrderService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.order = data.order;
        this.clients = data.clients;
        this.products = data.products;

        let delivered_at = moment(this.order.delivered_at, 'YYYY-MM-DD HH:mm:ss').toDate();

        this.orderForm = this.fb.group({
            client: new FormControl(this.order.client, Validators.required),
            delivered_at: new FormControl((new Date(delivered_at)).toISOString(), Validators.required),
            unrestricted: new FormControl(this.order.unrestricted),
            instructions: new FormControl(this.order.instructions),
            amount: new FormControl(this.order.amount),
            total: new FormControl(this.order.total),
            products: this.orderProductsFormArray
        });

        this.order.order_products.forEach((product) => {
            this.orderProductsFormArray.push(new FormGroup({
                product: new FormControl(product.product, Validators.required),
                unit_price: new FormControl(product.unit_price, [Validators.required, Validators.min(0)]),
                amount: new FormControl(product.amount, [Validators.required, Validators.min(0.1)]),
                total: new FormControl(product.unit_price * product.amount)
            }));
        }, this);

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.client_id = (params.client_id) ? params.client_id : '0';
        });

        this.filteredClients = this.orderForm.controls['client'].valueChanges
            .pipe(startWith(null))
            .pipe(map(client => client && typeof client === 'object' ? client.name : client))
            .pipe(map(name => name ? this.filterClient(name) : this.clients.slice()));
    }

    focusProduct(product: FormControl): void {
        this.filteredProducts = product.valueChanges
            .pipe(startWith(null))
            .pipe(map(product => product && typeof product === 'object' ? product.name : product))
            .pipe(map(name => name ? this.filterProduct(name) : this.products.slice()))
    }

    filterClient(name: string): any {
        return this.clients.filter(client => client.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    filterProduct(name: string): any {
        return this.products.filter(product => product.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    displayFn(option: any): string {
        return option ? option.name : option;
    }

    addOrderProduct(): void {
        this.orderProductsFormArray.push(new FormGroup({
            product: new FormControl(null, Validators.required),
            unit_price: new FormControl(0, [Validators.required, Validators.min(0)]),
            amount: new FormControl(0, [Validators.required, Validators.min(0.1)]),
            total: new FormControl(0)
        }));
    }

    removeOrderProduct(i: number): void {
        this.orderProductsFormArray.removeAt(i);
        this.updateTotal();
    }

    valueChange(i: number): void {
        this.orderProducts[i].controls['total'].setValue(this.orderProducts[i].controls['unit_price'].value * this.orderProducts[i].controls['amount'].value);
        this.updateTotal();
    }

    updateTotal(): void {
        this.globalTotal = 0;
        this.globalTotalAmount = 0;
        this.orderProducts.forEach(function(product) {
            this.globalTotal += product.controls['total'].value;
            this.globalTotalAmount += product.controls['amount'].value;
        }, this);
        this.orderForm.controls['total'].setValue(this.globalTotal);
        this.orderForm.controls['amount'].setValue(this.globalTotalAmount);
    }

    handler(): void {
        if(this.orderForm.valid) {
            this.loading = true;
            this.orderService.updateOrder(this.order.id, this.orderForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Venta Guardada!', '', {duration: 2500});
                setTimeout(() => {this.location.back();}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar la venta. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
