import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ClientService } from '../services/client/client.service';

@Component({
    selector: 'client-edit',
    templateUrl: './client-edit.component.html'
})
export class ClientEditComponent implements OnInit {
    public title: string = 'Editar Cliente';
    public client: any;
    public clientForm: FormGroup;
    public loading: boolean = false;

    constructor(private snack: MatSnackBar, private location: Location, private route: ActivatedRoute, private fb: FormBuilder, private clientService: ClientService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.client = data.client;

        this.clientForm = this.fb.group({
            name: new FormControl(this.client.name, Validators.required),
            requires_certification: new FormControl(this.client.requires_certification)
        });
    }

    handler() {
        if (this.clientForm.valid) {
            this.loading = true;
            this.clientService.updateClient(this.client.id, this.clientForm.value).subscribe(() => {
                this.loading = false;
                this.snack.open('¡Cliente Guardado!', '', { duration: 1500 });
                setTimeout(() => { this.location.back(); }, 1550);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo actualizar el cliente. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
