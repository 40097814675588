import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from '../../api';

@Injectable()
export class DashboardService {
    private url: string = API + '/dashboard';

    constructor(private http: HttpClient) { }

    getDashboard(): Observable<any> {
        return this.http.get(this.url);
    }

    getGraph(): Observable<any> {
        return this.http.get(`${this.url}/graph`);
    }

    getRequests(ids: any): Observable<any> {
        return this.http.get(`${this.url}/request?ids=${ids}`);
    }

    getOrders(ids: any): Observable<any> {
        return this.http.get(`${this.url}/order?ids=${ids}`);
    }

    getPendingPrices(): Observable<any> {
        return this.http.get(`${this.url}/pending-price`);
    }

    getResume(): Observable<any> {
        return this.http.get(`${this.url}/resume`);
    }

    getPendingOrders(): Observable<any> {
        return this.http.get(`${this.url}/pending-order`);
    }

    getLowInventory(): Observable<any> {
        return this.http.get(`${this.url}/low-inventory`);
    }
}
