import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ClientService } from '../services/client/client.service';

@Component({
    selector: 'client-create',
    templateUrl: './client-create.component.html'
})
export class ClientCreateComponent implements OnInit {
    public title: string = 'Crear Cliente';
    public clientForm: FormGroup;
    public loading: boolean = false;

    constructor(private snack: MatSnackBar, public location: Location, private route: ActivatedRoute, private fb: FormBuilder, private clientService: ClientService) { }

    ngOnInit() {
        this.clientForm = this.fb.group({
            name: new FormControl(null, Validators.required),
            requires_certification: new FormControl(false)
        });
    }

    handler() {
        if (this.clientForm.valid) {
            this.loading = true;
            this.clientService.createClient(this.clientForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Cliente Guardado!', '', { duration: 2500 });
                setTimeout(() => { this.location.back(); }, 2550);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar el cliente. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
