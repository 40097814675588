import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ProviderService } from '../services/provider/provider.service';

@Component({
    selector: 'provider-edit',
    templateUrl: './provider-edit.component.html'
})
export class ProviderEditComponent implements OnInit {
    public title: string = 'Proveedores';
    public provider: any;
    public providerForm: FormGroup;
    public loading: boolean = false;
    public params: any = {};

    constructor(private snack: MatSnackBar, public location: Location, private route: ActivatedRoute, private fb: FormBuilder, private providerService: ProviderService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.provider = data.provider;
        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });

        this.providerForm = this.fb.group({
            name: new FormControl(this.provider.name, Validators.required)
        });
    }

    handler() {
        if(this.providerForm.valid) {
            this.loading = true;
            this.providerService.updateProvider(this.provider.id, this.providerForm.value).subscribe(() => {
                this.loading = false;
                this.snack.open('¡Proveedor Guardado!', '', { duration: 1500 });
                setTimeout(() => { this.location.back(); }, 1550);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo actualizar el proveedor. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
