import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ProductService } from '../services/product/product.service';

@Component({
    selector: 'app-product-create',
    templateUrl: './product-create.component.html'
})
export class ProductCreateComponent implements OnInit {
    public title: string = 'Nuevo Producto';
    public data: any;
    public units: any;
    public types: any;
    public families: any;
    public productForm: FormGroup;
    public loading: boolean = false;
    public params: any = {};

    constructor(public location: Location, private snack: MatSnackBar, private route: ActivatedRoute, private fb: FormBuilder, private router: Router, private ps: ProductService) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.units = data.units;
        this.types = data.types;
        this.families = data.families;
        this.productForm = this.fb.group({
            name: new FormControl(''),
            name_origin: new FormControl(''),
            description: new FormControl(''),
            minimum: new FormControl(0),
            unit_price: new FormControl(0),
            measurement_unit: new FormControl(null),
            product_type: new FormControl(null),
            family: new FormControl(null),
            is_composite: new FormControl(false)
        });

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
    }

    compare(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    handler(): void {
        if(this.productForm.valid) {
            this.loading = true;
            this.ps.storeProduct(this.productForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('Producto Guardado!', '', {duration: 2500});
                setTimeout(() => {this.location.back();}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo guardar el producto. Intentalo más tarde.', 'Cerrar');
            });
        }
    }

}
