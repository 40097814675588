import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../services/session/session.service';
import { ProductService } from '../services/product/product.service';
import { Batch } from '../batch';
import { SERVER } from '../server';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { merge, fromEvent } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
    selector: 'product-detail',
    templateUrl: './product-detail.component.html'
})
export class ProductDetailComponent implements OnInit {
    public data: any;
    public product: any;
    public batchesColumns = ['code', 'amount', 'entered_at', 'state', 'actions'];
    public batches: MatTableDataSource<any>;
    public server: string = SERVER;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public params: any = {};
    public deleting: boolean = false;

    constructor(public location: Location, private route: ActivatedRoute, private router: Router, public session: SessionService, private productService: ProductService, private snack: MatSnackBar, public dialog: MatDialog) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.product = data.product;
        this.batches = new MatTableDataSource(this.product.batch_products);
        this.batches.paginator = this.paginator;
        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
        });
    }

    relabel(pid, bid): void {
        this.productService.relabel(pid, bid).subscribe((res) => {
            this.snack.open('¡Etiquetas regeneradas!', '', {duration: 1000});
            setTimeout(() => {this.router.navigateByUrl('/app/product/' + this.product.id);}, 1200);
        }, (err) => {
            this.snack.open('No se pudo regenerar las etiquetas. Inténtalo más tarde.', 'Cerrar');
        });
    }

    delete(): void {
        this.deleting = true;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.productService.deleteProduct(+this.product.id).subscribe((res) => {
                    this.deleting = false;
                    this.snack.open('El producto fue eliminado correctamente', '', {duration: 2500});
                    setTimeout(() => {this.router.navigateByUrl('/app/product');}, 2600);
                }, (err) => {
                    this.deleting = false;
                    this.snack.open('No se pudo eliminar el producto. Inténtalo más tarde.', 'Cerrar');
                });
            } else {
                this.deleting = false;
            }
        });
    }

}
