import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session/session.service';
import { OrderService } from '../services/order/order.service';

@Component({
    selector: 'order-return',
    templateUrl: './order-return.component.html'
})
export class OrderReturnComponent implements OnInit {
    public title: string = 'Devolución';
    public orderReturnForm: FormGroup;
    public orderProducts: Array<FormGroup> = [];
    public orderProductsFormArray: FormArray = new FormArray(this.orderProducts);
    public order: any;
    public boxes: Array<any> = [];
    public loading: boolean = false;
    public params: any = {};

    constructor(private snack: MatSnackBar, private router: Router, private route: ActivatedRoute, public session: SessionService, private orderService: OrderService, private fb: FormBuilder) { }

    ngOnInit() {
        let data = this.route.snapshot.data;
        this.order = data.order;

        this.orderReturnForm = this.fb.group({
            products: this.orderProductsFormArray
        });

        this.order.order_products.forEach((product, i) => {
            this.boxes.push([]);
            let index = this.boxes.length - 1;
            this.orderProductsFormArray.push(new FormGroup({
                batch: new FormControl(null, Validators.required),
                original_batch: new FormControl(this.order.batch_products[i].batch_id),
                product: new FormControl(product.product, Validators.required),
                amount: new FormControl(product.amount, Validators.required),
                boxes: new FormArray(this.boxes[index])
            }));
        }, this);

        this.route.queryParams.subscribe(params => {
            this.params.page = (params.page) ? params.page : 0;
            this.params.s = (params.s) ? params.s : null;
            this.params.state_id = (params.state_id) ? params.state_id : '0';
            this.params.client_id = (params.client_id) ? params.client_id : '0';
        });
    }

    addProductBox(product): void {
        product.controls['boxes'].push(new FormGroup({
            box_count: new FormControl(0, [Validators.required, Validators.min(1)]),
            amount_per_box: new FormControl(0, [Validators.required, Validators.min(1)])
        }));
    }

    removeProductBox(product: any, j: number): void {
        product.controls['boxes'].removeAt(j);
    }

    handler(): void {
        if(this.orderReturnForm.valid) {
            this.loading = true;
            this.orderService.returnOrder(this.order.id, this.orderReturnForm.value).subscribe((res) => {
                this.loading = false;
                this.snack.open('¡Venta Regresada!', '', {duration: 2500});
                setTimeout(() => {this.router.navigateByUrl('/app/order/' + this.order.id);}, 2600);
            }, (err) => {
                this.loading = false;
                this.snack.open('No se pudo completar el retorno. Inténtalo más tarde.', 'Cerrar');
            });
        }
    }

}
