import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RequestService } from '../../services/request/request.service';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RequestStatesResolve implements Resolve<any> {

    constructor(private rs: RequestService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.rs.getStates().pipe(map(states => states)).pipe(first());
    }

}
